import axios from "axios";
import {BACKEND_ENPOINT} from "../constants";
// --------------------- //

const _ProductsAPI = BACKEND_ENPOINT + "/products";
const _ProductAPI = BACKEND_ENPOINT + "/product";
const _ProductCreateAPI = BACKEND_ENPOINT + "/product";
const _ProductUpdateAPI = BACKEND_ENPOINT + "/product";
const _ProductDeleteAPI = BACKEND_ENPOINT + "/product";

export async function _Products(params = "?") {
  try {
    const data = await axios.get(_ProductsAPI + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _Product(_id, params = "?") {
  try {
    const data = await axios.get(_ProductAPI + "/" + _id + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _ProductCreate(value) {
  try {
    const data = await axios.post(_ProductCreateAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _ProductUpdate(_id, value) {
  try {
    const data = await axios.put(_ProductUpdateAPI + "/" + _id, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _ProductDelete(_id) {
  try {
    const data = await axios.delete(_ProductDeleteAPI + "/" + _id);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}
