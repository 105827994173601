import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import Table from "../../components/Table";
import { useStore } from "../../providers/useStore";
import AddAdsPopup from "../../components/popup/AddAdsPopup";
import EditAdsPopup from "../../components/popup/EditAdsPopup";
import DeletePopup from "../../components/popup/DeletePopup";
import {
  _AdsCreate,
  _AdsUpdate,
  _AdsDelete,
  _Adss,
} from "../../apis/Adss.service";

export default function AdssPage() {
  // state
  const { adsLoading, setAdsLoading } = useStore();
  const { adss, setAdss } = useStore();
  const [select, setSelect] = useState();
  const [popAddAds, setPopAddAds] = useState(false);
  const [popEditAds, setPopEditAds] = useState(false);
  const [popDeleteAds, setPopDeleteAds] = useState(false);

  // functions
  const addAds = async (e) => {
    setPopAddAds(false);
    const data = await _AdsCreate(e);
    getData();
  };
  const updateAds = async (e) => {
    setPopEditAds(false);
    const data = await _AdsUpdate(e?._id, e);
    getData();
  };
  const deleteAds = async () => {
    setPopDeleteAds(false);
    const data = await _AdsDelete(select?._id);
    getData();
  };

  const getData = async () => {
    setAdsLoading(true);
    const data = await _Adss();
    if (!data?.err) {
      setAdss(data?.data);
    }
    setAdsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            ຈັດການ Ads
          </div>
          <Button
            size="large"
            variant="contained"
            onClick={() => setPopAddAds(true)}
          >
            ເພີ່ມ Ads
          </Button>
        </div>
        <Table>
          <tr>
            <th>ລຳດັບ</th>
            <th>ຮູບ</th>
            <th>ເປີດ/ປິດ</th>
            <th>ຈັດການ</th>
          </tr>
          {adss?.map((e, i) => (
            <tr>
              <td>{i + 1}</td>
              <td>{e?.image}</td>
              <td>{e?.isPublic}</td>
              <td>
                <Box style={{ display: "flex", gap: 10 }}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setSelect(e);
                      setPopEditAds(true);
                    }}
                  >
                    edit
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setSelect(e);
                      setPopDeleteAds(true);
                    }}
                  >
                    delete
                  </Button>
                </Box>
              </td>
            </tr>
          ))}
        </Table>
      </div>
      {/* popup */}
      <AddAdsPopup
        open={popAddAds}
        onClose={() => setPopAddAds(false)}
        onSubmit={addAds}
      />
      <EditAdsPopup
        value={select}
        open={popEditAds}
        onClose={() => setPopEditAds(false)}
        onSubmit={updateAds}
      />
      <DeletePopup
        text={select?.name}
        open={popDeleteAds}
        onClose={() => setPopDeleteAds(false)}
        onSubmit={deleteAds}
      />
    </>
  );
}
