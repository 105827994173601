import React, { useState, useEffect } from 'react'

import {
    FormGroup,
    FormLabel,
    TextField,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

function ChoiceForm({ choices, item, form }) {
    const [choicesList, setChoiceList] = useState(choices)

    const addChoice = () => {
        const values = [...choicesList];
        values.push({
            name: ""
        });
        setChoiceList(values);
    }

    useEffect(() => {
        item.choices = choicesList
    }, [choicesList])

    const removeOption = (i) => {
        const values = [...choicesList];
        values.splice(i, 1)
        setChoiceList(values)
    }

    const onKeyName = (i, e) => {
        let newArray = [...choicesList];
        newArray[i].name = e?.target?.value;
        setChoiceList(newArray);
    }

    return (
        <div style={{ marginTop: 20 }}>
            <FormLabel style={{display: "flex", alignItems: "center"}}>
                ຕົວເລືອກເພີມເຕີມ &nbsp;&nbsp; <ControlPointIcon onClick={addChoice} style={{cursor:"pointer"}} />
            </FormLabel>
            <br></br>
            {choicesList?.map((add, index) =>
                <FormGroup key={index} style={{ marginBottom: 10, display: 'flex', flexDirection: "row", width: '100%', alignItems: "center", justifyContent: 'space-between' }}>
                    <TextField
                        placeholder="ລະບຸຄ່າ"
                        value={add.name || ""}
                        onChange={e => onKeyName(index, e)}
                        style={{ width: "90%" }}
                    />
                    <DeleteIcon onClick={() => removeOption(index)} style={{ color: "red" }} />
                </FormGroup>
            )}
        </div>
    )
}

export default ChoiceForm