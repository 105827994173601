import { useState } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";

export default function AuthState(){
  const [authLoading, setAuthLoading] = useState(false);
  const [token, setToken] = useLocalStorage("token", "");
  const [storedData, setStoredData] = useLocalStorage("store", "");

  return {
    authLoading,
    setAuthLoading,
    token,
    setToken,
    setStoredData,
    storedData
  };
};
