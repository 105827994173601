import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import Table from "../../components/Table";
import { useStore } from "../../providers/useStore";
import AddProductOptionPopup from "../../components/popup/AddProductOptionPopup";
import EditProductOptionPopup from "../../components/popup/EditProductOptionPopup";
import DeletePopup from "../../components/popup/DeletePopup";
import {
  _ProductOptionCreate,
  _ProductOptionUpdate,
  _ProductOptionDelete,
  _ProductOptions,
} from "../../apis/ProductOption.service";

export default function ProductOptionPage() {
  // state
  const { productOptionLoading, setProductOptionLoading } = useStore();
  const { productOptions, setProductOptions } = useStore();
  const [select, setSelect] = useState();
  const [popAddProductOption, setPopAddProductOption] = useState(false);
  const [popEditProductOption, setPopEditProductOption] = useState(false);
  const [popDeleteProductOption, setPopDeleteProductOption] = useState(false);

  // functions
  const addProductOption = async (e) => {
    setPopAddProductOption(false);
    const data = await _ProductOptionCreate(e);
    getData();
  };
  const updateProduct = async (e) => {
    setPopEditProductOption(false);
    const data = await _ProductOptionUpdate(e?._id, e);
    getData();
  };
  const deleteProductOption = async () => {
    setPopDeleteProductOption(false);
    const data = await _ProductOptionDelete(select?._id);
    getData();
  };

  const getData = async () => {
    setProductOptionLoading(true);
    const data = await _ProductOptions("?p=productId");
    if (!data?.err) {
      setProductOptions(data?.data);
    }
    setProductOptionLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            ຈັດການ Option ສິນຄ້າ
          </div>
          <Button
            size="large"
            variant="contained"
            onClick={() => setPopAddProductOption(true)}
          >
            ເພີ່ມ Option ສິນຄ້າ
          </Button>
        </div>
        <Table>
          <tr>
            <th>ລຳດັບ</th>
            <th>Option</th>
            <th>ສິນຄ້າ</th>
            <th>ລາຍລະອຽດ</th>
            <th>ລາຄາ</th>
            <th>ຈັດການ</th>
          </tr>
          {productOptions?.map((e, i) => (
            <tr>
              <td>{i + 1}</td>
              <td>{e?.name}</td>
              <td>{e?.productId?.name}</td>
              <td>{e?.detail}</td>
              <td>{e?.price}</td>
              <td>
                <Box style={{ display: "flex", gap: 10 }}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setSelect(e);
                      setPopEditProductOption(true);
                    }}
                  >
                    edit
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setSelect(e);
                      setPopDeleteProductOption(true);
                    }}
                  >
                    delete
                  </Button>
                </Box>
              </td>
            </tr>
          ))}
        </Table>
      </div>
      {/* popup */}
      <AddProductOptionPopup
        open={popAddProductOption}
        onClose={() => setPopAddProductOption(false)}
        onSubmit={addProductOption}
      />
      <EditProductOptionPopup
        value={select}
        open={popEditProductOption}
        onClose={() => setPopEditProductOption(false)}
        onSubmit={updateProduct}
      />
      <DeletePopup
        text={select?.name}
        open={popDeleteProductOption}
        onClose={() => setPopDeleteProductOption(false)}
        onSubmit={deleteProductOption}
      />
    </>
  );
}
