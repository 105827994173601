import axios from "axios";
import {BACKEND_ENPOINT} from "../../constants";
// --------------------- //

const _TypeAccountsAPI = BACKEND_ENPOINT + "/incomeAndExpends";
// const _AccountAPI = BACKEND_ENPOINT + "/color";
const _TypeAccountCreateAPI = BACKEND_ENPOINT + "/create-incomeAndExpend";
const _TypeAccountUpdateAPI = BACKEND_ENPOINT + "/update-incomeAndExpend";
const _TypeAccountDeleteAPI = BACKEND_ENPOINT + "/delete-incomeAndExpend";

export async function _TypeAccounts(params = "?") {
  try {
    const data = await axios.get(_TypeAccountsAPI + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

// export async function _Account(_id, params = "?") {
//   try {
//     const data = await axios.get(_AccountAPI + "/" + _id + params);
//     if (data.status < 300) {
//       return { err: false, data: data.data };
//     }
//     return { err: true, message: `err - ${data.status}` };
//   } catch (err) {
//     console.log("err", err);
//     return { err: true, message: err };
//   }
// }

export async function _TypeAccountCreate(value) {
  try {
    const data = await axios.post(_TypeAccountCreateAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _TypeAccountUpdate(_id, value) {
  try {
    const data = await axios.put(_TypeAccountUpdateAPI + "/" + _id, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _TypeAccountDelete(_id) {
  try {
    const data = await axios.delete(_TypeAccountDeleteAPI + "/" + _id);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}
