import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";

export default function DeletePopup({ open, onClose, text, onSubmit }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      maxWidth='md'
      sx={{ overflowY: "auto" }}
    >
      <Box
        sx={{
          borderRadius: "8px",
          boxShadow: 1,
          p: 2,
          bgcolor: "background.paper",
          transform: "translate(-50%, -50%)",
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          maxWidth: 300,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p>
         {text || "***"}
        </p>
        
        <Box sx={{ display: "flex", justifyContent: "end", marginTop:5 }}>
          <Button onClick={onSubmit} variant="contained">
            ລົບ
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
