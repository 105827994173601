import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import AdsForm from "../form/AdsForm";

export default function EditAdsPopup({ open, onClose, onSubmit, value }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{ overflowY: "auto" }}
    >
      <Box
        sx={{
          borderRadius: "8px",
          boxShadow: 1,
          p: 2,
          bgcolor: "background.paper",
          transform: "translate(-50%, -50%)",
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          maxWidth: 500,
        }}
      >
        <div style={{ fontSize: 18, fontWeight: "bold", marginBottom: 20 }}>
          ແກ້ໄຂ Option ສິນຄ້າ
        </div>
        <hr style={{ marginBottom: 20 }} />
        <AdsForm value={value} onSubmit={onSubmit} />
      </Box>
    </Modal>
  );
}
