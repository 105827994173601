import React from 'react'
import { Formik } from "formik";

//api
import { _TypeAccountUpdate } from "../../../../../apis"
import { useStore } from "../../../../../providers/useStore";

//component
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//mui
import { FormGroup, FormLabel, TextField, Box, Button, Modal, Backdrop, Select, MenuItem } from "@mui/material";


function FormEditATypeIncomeAndExpend({ showFormEdit, clearEdit, fetchData, typeAccountInfo }) {
    const { storedData } = useStore();

    /**
     * function
     */
    const notifyError = (text) => toast.error(text, { autoClose: 500, });
    const notifySuccess = (text) => toast.success(text, { autoClose: 500, });
    const notifyWarning = (text) => toast.warning(text, { autoClose: 500, });

    const onSaveCreate = async (values) => {
        let _data = {
            ...values,
            storeId: storedData?.storeId
        }

        const result = await _TypeAccountUpdate(typeAccountInfo?._id, _data)
        if (result?.err) {
            if (result?.message?.response?.data?.message === "THIS_TYPE_IS_ALREADY") {
                notifyWarning(`ລາຍການນີ້ມີແລ້ວໃນລະບົບ`)
                return
            }

            if (result?.message?.response?.data?.message === "PLEASE_ENTER_ALL_FIELEDS") {
                notifyWarning(`ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບ`)
                return;

            }
            notifyError(`ບໍ່ສາມາດເພີ່ມໄດ້`)
            return;
        }
        else {
            await clearEdit()
            await fetchData()
            await notifySuccess('ບັນທຶກສຳເລັດ')
        }
    }

    //validate 
    const val = () => {
        return <span style={{ color: "red" }}>*</span>
    }
    return (
        <>
            {typeAccountInfo && (
                <Formik
                    initialValues={{
                        title: typeAccountInfo?.title ?? "",
                        type: typeAccountInfo?.type ?? "",
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.title) errors.title = "ກະລຸນາປ້ອນຊື່ປະເພດ"
                        if (!values.type) errors.type = "ກະລຸນາເລືອກສະຖານະ"

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        (async () => {
                            if(typeAccountInfo?.title === values.title) delete values.title;
                            if(typeAccountInfo?.type === values.type) delete values.type;

                            await onSaveCreate(values);
                            setSubmitting(false);
                            resetForm();
                        })();
                    }}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal
                                open={showFormEdit}
                                onClose={() => clearEdit()}
                                sx={{ overflowY: "auto" }}
                            >
                                <Box
                                    sx={{
                                        borderRadius: "8px",
                                        boxShadow: 1,
                                        p: 2,
                                        bgcolor: "background.paper",
                                        transform: "translate(-50%, -50%)",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        width: "100%",
                                        maxWidth: 500,
                                    }}
                                >
                                    <div style={{ fontSize: 18, fontWeight: "bold", marginBottom: 20 }}>
                                        ເພີ່ມປະເພດລາຍຮັບ-ລາຍຈ່າຍ
                                    </div>
                                    <hr style={{ marginBottom: 20 }} />
                                    <FormGroup >
                                        <FormLabel>ຊື່ປະເພດ {val()}</FormLabel>
                                        <TextField
                                            type='text'
                                            placeholder="ປ້ອນຊື່ປະເພດ"
                                            size="small"
                                            value={values?.title}
                                            name="title"
                                            error={!!errors.title}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>

                                    <FormGroup style={{ marginTop: 10 }}>
                                        <FormLabel>
                                            ສະຖານະ {val()}
                                        </FormLabel>
                                        <Select
                                            value={values?.type}
                                            name="type"
                                            error={!!errors.type}
                                            onChange={handleChange}
                                            size="small"
                                        >
                                            <MenuItem value={"INCOME"}>ລາຍຮັບ</MenuItem>
                                            <MenuItem value={"EXPEND"}>ລາຍຈ່າຍ</MenuItem>
                                        </Select>

                                    </FormGroup>



                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                                        <Button
                                            type="button"
                                            onClick={() => clearEdit()}
                                            sx={{ boxShadow: 0, marginTop: 3 }}
                                            size="large"
                                        >
                                            ຍົກເລີກ
                                        </Button>
                                        &nbsp;
                                        <Button
                                            onClick={handleSubmit}
                                            disabled={isSubmitting}
                                            variant="contained"
                                            sx={{ boxShadow: 0, marginTop: 3 }}
                                            size="large"
                                        >
                                            ບັນທຶກ
                                        </Button>
                                    </div>
                                </Box>
                            </Modal>
                        </form>
                    )}
                </Formik>
            )}
        </>
    )
}

export default FormEditATypeIncomeAndExpend