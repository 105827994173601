import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import { NumericFormat } from 'react-number-format';


//constant
import { PAGE_GINATION } from "../../../constants"


//components
import { TitleForm, Loading } from "../../../components"
import UploadPhoto from "../../../components/UploadImageMany";
import ChoiceForm from "./ChoiceForm";

//api
import {
    _ProductUpdate,
    _Product
} from "../../../apis/Products.service";
import { _ProductTypes } from "../../../apis/ProductType.service";
import { _Store } from "../../../apis/Stores.service"

import { useStore } from "../../../providers/useStore";

//mui
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    FormGroup,
    FormLabel,
    TextField,
    FormControl,
    MenuItem,
    Select,
    Button,
    Link,
    Breadcrumbs,
    Typography,
    Grid
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function ProductsEdit() {
    const navigate = useNavigate();
    const param = useParams()
    const productId = param.productId
    const { storedData } = useStore();

    // state
    const [storeInfo, setStoreInfo] = useState()
    const [form, setForm] = useState()
    // const theme = useTheme();
    const { productTypeLoading, setProductTypeLoading } = useStore();
    const { productTypes, setProductTypes } = useStore();
    //img
    const {
        namePhotoMany,
        buttonUploadAndShowPhotoMany,
        setNamePhotoMany
    } = UploadPhoto();

    // option
    const [options, setOptions] = useState([])

    //color
    const [colorName, setColorName] = useState([]);
    const [productDetail, setProductDetail] = useState()


    //useEffect
    useEffect(() => {
        let storeLocal = window.localStorage.getItem('store');
        let storeIdLocal = JSON.parse(storeLocal)
        if (storeIdLocal === null) return;
        getStoreInfo(storeIdLocal?.storeId)
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [])

    useEffect(() => {
        if (productId) getProduct(productId)
        if (storedData) getData(storedData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, storedData])

    useEffect(() => {
        if (productDetail) {
            setNamePhotoMany(productDetail?.images)
            setOptions(productDetail?.options)
            setColorName(productDetail?.colors)
            setForm(productDetail?.productOption)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productDetail])

    const getStoreInfo = async (storedData) => {
        const param = storedData
        const responseStore = await _Store(param)
        if (responseStore?.data !== null) {
            setStoreInfo(responseStore?.data)
        }
    }

    const getProduct = async (productId) => {
        const data = await _Product(productId);
        setProductDetail(data?.data)
    }

    const getData = async () => {
        setProductTypeLoading(true);

        let params = "?storeId=" + storedData?.storeId;

        const dataProductype = await _ProductTypes(params);
        if (!dataProductype?.err) {
            setProductTypes(dataProductype?.data);
        }

        setProductTypeLoading(false);

    };

    // functions
    const notifyError = (text) => toast.error(text, { autoClose: 500, });
    const notifyWarning = (text) => toast.warning(text, { autoClose: 500, });
    const notifySuccess = (text) => toast.success(text, { autoClose: 500, });


    const addProduct = async (e) => {
        if (namePhotoMany.length <= 0) { return notifyWarning("ກະລຸນາເລືອກຮູບສິນຄ້າ") }
        const data = await _ProductUpdate(productDetail?._id, e);
        if (!data?.err) {
            await notifySuccess('ແກ້ໄຂສິນຄ້າສຳເລັດ')
            setTimeout(() => {
                navigate('/product' + PAGE_GINATION)
            }, 1000);
        }
        else notifyError("ແກ້ໄຂສິນຄ້າບໍ່ສຳເລັດ")
    };


    //validate 
    const val = () => {
        return <span style={{ color: "red" }}>*</span>
    }

    const addForm = () => {
        const values = [...form]
        let _code = Math.floor(Math.random() * (100 + 1)) + 1

        values.push({
            codeOption: _code, typeName: "ສີ", choices: [{ name: "" }]
        })
        setForm(values)
    }

    const removeForm = (i) => {
        const values = [...form]
        values.splice(i, 1)
        setForm(values)
    }

    const handSeleteType = (i, e) => {
        let newArray = [...form];
        newArray[i].typeName = e?.target?.value;
        setForm(newArray);
    };

    const typeName = [
        "ສີ",
        "ຂະໜາດ",
        "ລຸ້ນ",
        "ຄວາມຈຸ(storage)",
        "ນ້ຳໜັກ",
        "ບໍລິມາດ",
        "ວັດສະດຸ",
        "ຄວາມຍາວ",
        "ຄວາມສູງ",
        "ຄວາມກວ້າງ",
        "ຄວາມເລິກ",
        "ອາຍຸ",
        "ການເຊື່ອມຕໍ່",
        "ຍີ່ຫໍ້",
        "ຄວາມໜາ",
        "ຈຳນວນ",
        "ອັດຕາສ່ວນ",
        "CPU",
        "RAM",
        "OS",
        "ເກຣດ",
        "ກິ່ນ",
        "ປະເພດ",
        "ເຊັດ",
        "ລັກສະນາ",
        "ຜ້າ",
        "ລາຍ",
        "ຄ່າຄວາມໜືດ",
        "ຄວາມຈຸ (capacity)",
        "ລົດຊາດ",
        "ກຳລັງໄຟ (ວັດ)",
        "ອາຍຸ",
        "ແພັກ",
        "ໄລຍະເວລາ",
        "ລະດັບ",
        "colour"
    ];


    return (
        <>
            <header>
                <title>Unitech-Store-{storeInfo?.name}-ແກ້ໄຂສິນຄ້າ</title>
            </header>

            <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 20 }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" onClick={() => navigate('/product' + PAGE_GINATION)} style={{ cursor: "pointer" }}>
                            ຈັດການສິນຄ້າ
                        </Link>
                        <Typography color="text.primary">ຟອມແກ້ໄຂສິນຄ້າ</Typography>
                    </Breadcrumbs>
                </div>
                <TitleForm text={"ແກ້ໄຂສິນຄ້າ"} />




                {productTypeLoading ? (
                    <>
                        <Loading />
                    </>
                ) : productDetail && (

                    // {productDetail && (
                    /* ຟອມເພີ່ມສິນຄ້າ    */
                    <Formik
                        initialValues={{
                            name: productDetail?.name ?? "",
                            type: productDetail?.type?._id ?? "",
                            detail: productDetail?.detail ?? "",
                            price: productDetail?.price ?? "",
                            priceDiscount: productDetail?.priceDiscount ?? "",
                            status: productDetail?.status ?? "",
                            stock: productDetail?.stock ?? 0 ,
                            constPrice: productDetail?.constPrice ?? 0,
                            currency: productDetail?.currency ?? "",
                        }}
                        validate={(values) => {
                            const errors = {};
                            if (!values.name) errors.name = "ກະລຸນາຕື່ມຊື່ສິນຄ້າ"
                            if (!values.type) errors.type = "ກະລຸນາເລືອກປະເພດສິນຄ້າ"
                            if (!values.price) errors.price = "ກະລຸນາຕື່ມລາຄາເລີ່ມຕົ້ນ"
                            if (!values.status) errors.status = "ກະລຸນາເລືອກສະຖານະ"
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            (async () => {

                                let _price = values.price.toString()
                                let priceProduct = parseInt(_price.replaceAll(',', ''))
                                let _priceDiscount = values.priceDiscount.toString()
                                let priceDiscount = parseInt(_priceDiscount.replaceAll(',', ''))
                                let _constPrice = values.constPrice.toString()
                                let constPriceProduct = parseInt(_constPrice.replaceAll(',', ''))

                                let _data = {
                                    ...values,
                                    price: priceProduct ?? 0,
                                    priceDiscount: priceDiscount ?? 0,
                                    constPrice: constPriceProduct ?? 0,
                                    images: namePhotoMany,
                                    productOption: form,

                                }

                                delete values.price
                                delete values.priceDiscount

                                await addProduct(_data);
                                setSubmitting(false);
                            })();
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 10,
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <FormGroup>
                                                <FormLabel>ປ້ອນຊື່ສິນຄ້າ (ຊື່ສິນຄ້າບໍ່ຄວນຍາວເກີນ 20ຄຳສັບ) {val()}</FormLabel>
                                                <TextField
                                                    error={!!errors.name}
                                                    placeholder="ຊື່ສິນຄ້າ"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}

                                                />
                                            </FormGroup>

                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormGroup>
                                                <FormLabel>ຈຳນວນສະຕ໋ອກ {val()}</FormLabel>
                                                <TextField
                                                    error={!!errors.stock}
                                                    placeholder="ປ້ອນຈຳນວນສະຕ໋ອກ"
                                                    name="stock"
                                                    type="number"
                                                    value={values?.stock}
                                                    onChange={handleChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                            <FormGroup>
                                                <FormLabel>ປ້ອນລາຄາຕົ້ນທຶກສິນຄ້າ {val()}</FormLabel>
                                                <NumericFormat
                                                    allowLeadingZeros
                                                    thousandSeparator=","
                                                    decimalScale={2}
                                                    allowNegative={false}
                                                    error={!!errors.constPrice}
                                                    type="text"
                                                    placeholder="ລາຄາເລີ່ມຕົ້ນ"
                                                    name="constPrice"
                                                    customInput={TextField}
                                                    onChange={handleChange}
                                                    value={values?.constPrice}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FormGroup>
                                                <FormLabel>ປ້ອນລາຄາຂອງທ່ານ (ຄ່າເລີ່ມຕົ້ນ) {val()}</FormLabel>
                                                <NumericFormat
                                                    allowLeadingZeros
                                                    thousandSeparator=","
                                                    decimalScale={2}
                                                    allowNegative={false}
                                                    error={!!errors.price}
                                                    type="text"
                                                    placeholder="ລາຄາເລີ່ມຕົ້ນ"
                                                    name="price"
                                                    customInput={TextField}
                                                    onChange={handleChange}
                                                    value={values?.price}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FormGroup>
                                                <FormLabel>ປ້ອນສ່ວນຫຼຸດເປັນຈຳນວນເງິນ {val()}</FormLabel>

                                                <NumericFormat
                                                    allowLeadingZeros
                                                    thousandSeparator=","
                                                    decimalScale={2}
                                                    allowNegative={false}
                                                    type="text"
                                                    value={values.priceDiscount}
                                                    placeholder="ປ້ອນສ່ວນຫຼຸດເປັນຈຳນວນເງິນ"
                                                    name="priceDiscount"
                                                    customInput={TextField}
                                                    onChange={handleChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FormControl fullWidth>
                                                <FormLabel>
                                                    ສະກຸນເງິນ {val()}
                                                </FormLabel>
                                                <Select
                                                    value={values?.currency}
                                                    name="currency"
                                                    error={!!errors.currency}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"KIP"}>ກີບ</MenuItem>
                                                    <MenuItem value={"BATH"}>ບາດ</MenuItem>
                                                    <MenuItem value={"USD"}>ໂດລາ</MenuItem>
                                                    <MenuItem value={"CNY"}>ຢວນ</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                    </Grid>



                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <FormLabel>
                                                    ສະຖານະສິນຄ້າ {val()}
                                                </FormLabel>
                                                <Select
                                                    placeholder="ກະລຸນາເລືອກ..."
                                                    value={values.status}
                                                    name="status"
                                                    error={!!errors.status}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"INSTOCK"}>ພ້ອມສົ່ງ</MenuItem>
                                                    <MenuItem value={"PRE_ORDER"}>ພຣີອໍເດີ</MenuItem>
                                                    <MenuItem value={"OUT_ORDER"}>ສິນຄ້າໝົດ</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>



                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <FormLabel>
                                                    ປະເພດ (
                                                    <span
                                                        onClick={() => navigate("/product-type")}
                                                        style={{ color: "blue", cursor: "pointer" }}
                                                    >
                                                        ເພີ່ມປະເພດ
                                                    </span>
                                                    ) {val()}
                                                </FormLabel>
                                                <Select
                                                    placeholder="ກະລຸນາເລືອກ..."
                                                    error={!!errors.type}
                                                    value={values.type}
                                                    name="type"
                                                    onChange={handleChange}
                                                >
                                                    {productTypes?.map((e, i) => (
                                                        <MenuItem value={e?._id} key={i}>
                                                            {e?.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>



                                    </Grid>




                                    <FormGroup>
                                        <FormLabel>ລາຍລະອຽດສິນຄ້າ</FormLabel>
                                        <TextField
                                            name="detail"
                                            value={values.detail}
                                            onChange={handleChange}
                                            placeholder="ລາຍລະອຽດສິນຄ້າ..."
                                            multiline
                                            InputProps={{ style: { minHeight: 100, alignItems: "start" } }}

                                        />
                                    </FormGroup>




                                    <FormLabel>ຕົວເລືອກສິນຄ້າ</FormLabel>

                                    {form?.map((item, index) =>
                                        <>
                                            <div style={{ display: "flex", justifyContent: "end", color: "red", marginBottom: 0, zIndex: 1 }}>
                                                <CloseIcon onClick={() => removeForm(index)} style={{ backgroundColor: "#ffffff", borderRadius: "50%", cursor: "pointer" }} />
                                            </div>
                                            <div style={{
                                                backgroundColor: "#efefef",
                                                padding: 20,
                                                borderRadius: 10,
                                                marginTop: "-1.5rem",
                                            }}
                                                key={index}
                                            >

                                                <FormGroup>
                                                    <FormLabel>ປະເພດຕົວເລືອກ</FormLabel>
                                                    <Select
                                                        placeholder="ກະລຸນາເລືອກ..."
                                                        defaultValue={item?.typeName}
                                                        onChange={(e) => handSeleteType(index, e)}
                                                    >
                                                        {typeName.map((type, index) =>
                                                            <MenuItem value={type} key={index}>{type}</MenuItem>
                                                        )}
                                                    </Select>

                                                    <ChoiceForm choices={item?.choices} setForm={setForm} item={item} form={form} />

                                                </FormGroup>
                                            </div>
                                        </>
                                    )}

                                    <Button
                                        type="button"
                                        onClick={addForm}
                                        variant="contained"
                                        sx={{ boxShadow: 0, fontWeight: "700" }}
                                        size="large"
                                    >
                                        ເພີ່ມຕົວເລືອກ
                                    </Button>

                                    <p style={{ fontSize: "12px" }}>ອັບໂຫຼດຮູບພາບສິນຄ້າ (ທ່ານສາມາດອັບໂຫຼດໄດ້ບໍ່ເກີນ 10 ຮູບ)</p>

                                    <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
                                        {namePhotoMany?.length >= 10 ? "" : buttonUploadAndShowPhotoMany()}

                                    </div>


                                    <Button
                                        type="submit"
                                        onClick={handleSubmit}
                                        variant="contained"
                                        sx={{ boxShadow: 0, fontWeight: "700" }}
                                        size="large"
                                    >
                                        ບັກທຶກ
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                    /* ຟອມເພີ່ມສິນຄ້າ    */
                )}

                {/* )} */}

            </div>

            <ToastContainer />

        </>
    );
}
