import React from 'react'


//mui
import {
    FormGroup,
    FormLabel,
    TextField,
    FormControl,
    MenuItem,
    Select,
    Button,
    Link,
    Breadcrumbs,
    Typography,
    Grid
} from "@mui/material";
import DeshboardMobile from './mobile';


export default function AccountDeshboard() {
    return (
        <div>
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit"style={{ cursor: "pointer" }}>
                        ໜ້າຫລັກ
                    </Link>
                    <Typography color="text.primary">ຈັດການລາຍຮັບ-ລາຍຈ່າຍ</Typography>
                </Breadcrumbs>
            </div> */}

            <DeshboardMobile />

        </div>
    )
}
