// material
import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useEffect } from "react";
import { useStore } from "./providers";
// router
import Routers from "./routers";
// theme
import theme from "./themes";
import { CssBaseline } from "@mui/material";
import { StateProvider } from "./providers";
// import PrivateLayout from "./layouts/PrivateLayout";
import { ToastContainer } from "react-toastify";

// ---------------------------------------- //
function App() {
  return (
    <ThemeProvider theme={theme}>
      <StateProvider>
        <CssBaseline />
        {/* <PrivateLayout /> */}
        <Routers />
        <ToastContainer />
      </StateProvider>
    </ThemeProvider>
  );
}

export default App;
