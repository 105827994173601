import React, { useState } from 'react'
import { URL_PHOTO_AW3, PRESIGNED_URL } from "../constants";

import axios from 'axios'
import FileUploadIcon from '@mui/icons-material/FileUpload';

function UploadProfile() {
    const [widhtPhoto, setWidhtPhoto] = useState(200)
    const [heightPhoto, setHeightPhoto] = useState(200)
    const [namePhoto, setNamePhoto] = useState()
    const [imageLoading, setImageLoading] = useState()

    const handleUpload = async (event) => {
        setImageLoading('')
        try {
            // let fileDataBoole = event?.target?.files[0]
            // let fileDatatype = event?.target?.files[0]?.type
            // let checkFileUpload = fileDatatype.split('/')[0] !== 'application'
            // if (checkFileUpload === false) {
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'ກະລຸນາເລືອກຮູບພາບ',
            //         showConfirmButton: false,
            //         timer: 1500
            //     })
            //     return
            // }

            let fileData = event.target.files[0];

            const responseUrl = await axios({
                method: "post",
                url: PRESIGNED_URL,
                data: {
                    name: event.target.files[0].type,
                },
            });


            const _authorization = axios.defaults.headers.common["Authorization"];
            delete axios.defaults.headers.common["Authorization"];
            let afterUpload = await axios.put(responseUrl.data.url, fileData, {
                headers: {
                    "Content-Type": " file/*; image/*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    )
                    setImageLoading(percentCompleted)
                }
            });

            axios.defaults.headers.common["Authorization"] = _authorization;
            const url = afterUpload.config.url.split("?")[0];
            const splitName = url.split("/");
            const name = splitName[splitName.length - 1];

            setNamePhoto(name)

        } catch (error) { }
    }

    const ImageThumb = () => {
        return (
            <img
                src={URL_PHOTO_AW3 + "/" + namePhoto}
                alt={namePhoto}
                style={{
                    borderRadius: '10px',
                    height: heightPhoto,
                    width: widhtPhoto
                }}
            />
        )
    }


    const buttonUploadAndShowPhoto = () => {
        return (
            <div className='form-row'>
                <div className='col-12'>
                    <div className='form-group'>
                        <div style={{ textAlign: 'center' }}>
                            <input
                                type='file'
                                id='file-upload'
                                onChange={handleUpload}
                                hidden
                                accept="image/png, image/gif, image/jpeg, image/jpg"
                            />
                            <label htmlFor='file-upload'>
                                <div
                                    style={{
                                        height: heightPhoto,
                                        width: widhtPhoto,
                                        borderRadius: '10px',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        backgroundColor: '#DDDDDD'
                                    }}
                                >
                                    {namePhoto ? (
                                        <ImageThumb image={namePhoto} />
                                    ) : (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: heightPhoto,
                                                width: widhtPhoto,
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >

                                            <FileUploadIcon />
                                            ອັບໂຫລດຮູບ
                                        </div>
                                    )}
                                </div>
                                {/* progass */}
                                {imageLoading ? (
                                    <div className='progress' style={{ height: 20 }}>
                                        <div
                                            className='progress-bar'
                                            role='progressbar'
                                            style={{
                                                width: `${imageLoading}%`,
                                                backgroundColor: 'green'
                                            }}
                                            aria-valuenow={imageLoading}
                                            aria-valuemin='0'
                                            aria-valuemax='100'
                                        >
                                            {imageLoading}%
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ height: 20 }} />
                                )}
                            </label>






                        </div>
                    </div>
                </div>
            </div>
        )
    }





    return {
        namePhoto,
        setWidhtPhoto,
        setHeightPhoto,
        buttonUploadAndShowPhoto,
        setNamePhoto
    }
}

export default UploadProfile