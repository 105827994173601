import React from 'react'

//function
import {convertTypeAccount} from "../../../../../hooks/helpers"

//mui
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function MobileViewTypeAccount({ typeAccountList , setTypeAccountInfo, setConfirmDelete,setShowFormEdit}) {
    return (
        <div className="account-mobile-view">
            {typeAccountList.map((item, index) =>
                <div className='card-account'>
                    <div className='header'>
                        <h3>{item?.title ?? "-"}</h3>
                        <div className='manage'>
                            <EditIcon
                                onClick={() => {
                                          setTypeAccountInfo(item);
                                          setShowFormEdit(true);
                                        }}
                                style={{ color: "#27B43E",cursor:'pointer' }}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <DeleteIcon
                                onClick={() => {
                                    setTypeAccountInfo(item); setConfirmDelete(true)
                                }}
                                style={{ color: "#FF0000",cursor:'pointer' }}
                            />
                        </div>
                    </div>
                    <div className='body'>
                        <p style={{fontSize:12}}>{convertTypeAccount(item?.type ?? "-")}</p>
                    </div>
                   
                </div>
            )}
        </div>
    )
}

export default MobileViewTypeAccount