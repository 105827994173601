import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
// pages
import LoginPage from "../pages/LoginPage";

// Routes
import PrivateRoute from './PrivateRoute'
import Ads from "./Ads.routes";
import HomeRoutes from "./Home.routes";
import ProductsRoutes from "./Products.routes";
import OrderHistoryRoutes from "./History.routes"
import InviteFirend from "./InviteFriend.routes";
import ProfileRoutes from "./Profile.routes"
import SettingRouter from "./Setting.router"
import AccountRouter from "./Account.routes"

import { Outlet } from "react-router-dom";

// ------------------------ //

function Router() {
  return useRoutes([
    {
      path: "/",
      element: <PrivateRoute />,
      children: [
        { path: "/", element: <Navigate to="/home" /> },
        Ads,
        HomeRoutes,
        ProductsRoutes,
        OrderHistoryRoutes,
        InviteFirend,
        ProfileRoutes,
        SettingRouter,
        AccountRouter
      ],
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "*",
      element: <h1>404 page</h1>,
    },
  ]);
}

export default Router;
