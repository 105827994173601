

import { Outlet } from "react-router-dom";
import Profile_page from "../pages/profile/Profile_page"
import ProfileEdit from "../pages/profile/form/ProfileEdit"

// eslint-disable-next-line
export default {
    path: "/",
    element: <Outlet />,
    children: [
      { 
        path:"profile",
        element: <Profile_page />,
      },
      { 
        path:"profile-edit/:storeId",
        element: <ProfileEdit />,
      },
    
    
    ]
    
  };