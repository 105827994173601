import React from "react";
import * as _ from "lodash";
import moment from "moment"

export const formatDate = (dateTime) => {
    moment.locale("lo");
    let resp = moment(dateTime).format("DD-MM-YYYY");
    if (dateTime) return resp;
    else return "";
};


export const formatCurrency = (int) => {
    var formatter = new Intl.NumberFormat("en-US");

    return formatter.format(int);
}
export default formatCurrency;


export const converStatus = (status) => {
    let res = "";
    switch (status) {
        case "INSTOCK":
            res = "ພ້ອມສົ່ງ";
            break;
        case "PRE_ORDER":
            res = "ພຣີອໍເດີ";
            break;
        case "OUT_ORDER":
            res = "ໝົດສະຕ໋ອກ";
            break;
    }
    return res;
};


export const convertTypeCurrency = (currency) => {
    let res = "";
    switch (currency) {
        case "KIP":
            res = "ກີບ";
            break;
        case "BATH":
            res = "ບາດ";
            break;
        case "USD":
            res = "ໂດລາ";
            break;
        case "CNY":
            res = "ຢວນ";
            break;
    }
    return res;
};

export const convertTypeAccount = (type) => {
    let res = "";
    switch (type) {
        case "INCOME":
            res = "ລາຍຮັບ";
            break;
        case "EXPEND":
            res = "ລາຍຈ່າຍ";
            break;
    }
    return res;
};
