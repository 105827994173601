import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import axios from "axios";
import parseJwt from "../hooks/parseJwt";
import { useStore } from "../providers";
import PrivateLayout from "../layouts/PrivateLayout";

export default function PrivateRoute() {
  const { token } = useStore();
  const decodedJwt = parseJwt(
    axios.defaults.headers.common["Authorization"]?.split(" ")?.[1] || token
  );

  if (decodedJwt && Date.now() < decodedJwt.exp * 1000) {
    axios.defaults.headers.common["Authorization"] = `STORE ${token}`;
    return (
      <>
      <PrivateLayout />
      <Outlet />
      </>
    );
  }
  return <Navigate to="/login" />;
}
