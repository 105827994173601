import React, { useEffect, useState } from "react";
import { useStore } from "../../providers/useStore";
import { useNavigate } from "react-router-dom";

//function
import {formatDate} from "../../hooks/helpers"

//components
import { CustomButton, Title, Loading } from "../../components"

//apis
import { _Orders } from "../../apis/Orders.service"



// ui
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled } from '@mui/material/styles';
import {
    Box, Paper, Grid, FormGroup,
    FormLabel,
    TextField,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    padding: 10
    // height: 60,
    // lineHeight: '60px',
}));


export default function InviteFirend() {
    //constant
    const navigate = useNavigate();

    const { storedData } = useStore();
    const { orderLoading, setOrderLoading, orders, setOrders } = useStore();

    //state
    //filter
    const [byFullname, setByFullname] = useState("");
    const [byPhone, setByPhone] = useState("");
    const [byDateStart, setByDateStart] = useState("");
    const [byDateEnd, setByDateEnd] = useState("");

    //useEffect
    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        getData()
    }, [byFullname, byPhone, byDateStart, byDateEnd])

    const getData = async () => {
        setOrderLoading(true)
        let params = "?storeId=" + storedData?.storeId;
        if (byFullname !== "") params = "?storeId=" + storedData?.storeId + "&fullname=" + byFullname
        if (byPhone !== "") params = "?storeId=" + storedData?.storeId + "&phone=" + byPhone
        // if(byDateStart !== "" && byDateEnd!=="") params = "?storeId=" + storedData?.storeId + "&createdAt_gte="+byDateStart+"&createdAt_lt="+byDateEnd
        if (byFullname !== "" && byPhone !== "") params = "?storeId=" + storedData?.storeId + "&fullname=" + byFullname + "&phone=" + byPhone

        const data = await _Orders(params);
        if (!data?.err) {
            setOrders(data?.data);
        }
        setOrderLoading(false)
    }


    const [popSearch, setPopSearch] = useState(false)

    const _handleKeyFullname = (e) => {
        setByFullname(e?.target?.value)
    }
    const _handleKeyPhone = (e) => {
        setByPhone(e?.target?.value)
    }
    const _handleKeyDateStart = (e) => {
        setByDateStart(e?.target?.value)
    }

    const _handleKeyDateEnd = (e) => {
        setByDateEnd(e?.target?.value)
    }

    const skeleton =[1,2,3,4,5,6,7,8,9,10]

    return (

        <Box container spacing={2} style={{ padding: 20 }}>

            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
                <Title text={"ລາຍການເຊີນໝູ່"} />
                <CustomButton width={130} hidden={28} title="ເຊີນໝູ່" onClick={() => navigate("/invite-add")} />
            </div>

            <Grid container spacing={2} style={{ marginBottom: 10 }}>
                <Grid item xs={6}>
                    <FormGroup>
                        <FormLabel>ຄົ້ນຫາຕາມຊື່</FormLabel>
                        <TextField
                            placeholder="ຄົ້ນຫາຕາມຊື່"
                            name="name"
                            value={byFullname}
                            size="small"
                            onChange={(e) => _handleKeyFullname(e)}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6}>
                    <FormGroup>
                        <FormLabel>ຄົ້ນຫາຕາມເບີໂທ</FormLabel>
                        <TextField
                            placeholder="ຄົ້ນຫາຕາມເບີໂທ"
                            name="name"
                            value={byPhone}
                            size="small"
                            onChange={(e) => _handleKeyPhone(e)}

                        />
                    </FormGroup>
                </Grid>

            </Grid>



            <List sx={{ width: '100%', maxWidth: "100%", bgcolor: 'background.paper' }}>
                {orderLoading ? skeleton?.map((ske) =>
                    <>
                        <ListItem alignItems="flex-start" sx={{ width: '100%', maxWidth: "100%", bgcolor: 'background.paper' }}>
                            <ListItemAvatar>
                            <Skeleton variant="circular" width={50} height={50} />
                            </ListItemAvatar>
                            <Stack spacing={1}>
                            <Skeleton variant="text" width={260} sx={{ fontSize: '1rem', }} />
                            <Skeleton variant="text" width={260} sx={{ fontSize: '1rem' , }} />
                            <Skeleton variant="text" width={260} sx={{ fontSize: '1rem', }} />
                            <Skeleton variant="text" width={260} sx={{ fontSize: '1rem', }} />
                            </Stack>
                        </ListItem>
                        <Divider variant="inset" component="li" />

                    </>
                ) : orders?.map((order, index) =>
                    <>
                        <ListItem alignItems="flex-start" key={index}>
                            <ListItemAvatar>
                                <Avatar alt={order?.fullname} src="/static/images/avatar/2.jpg" />
                            </ListItemAvatar>
                            <ListItemText
                                primary={order?.fullname}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            ເບີໂທ: {order?.fullname}
                                        </Typography>
                                        <br></br>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            ສະຖານທີ່ສົ່ງ: {order?.address}
                                        </Typography>
                                        <br></br>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            ສະຖານະ: ....
                                        </Typography>
                                        <br></br>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            ວັນທີສັ່ງ:{formatDate(order?.createdAt)}
                                        </Typography>
                                        
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </>
                )}


            </List>







            <ToastContainer />

            {/* //search */}
            <Modal
                open={popSearch}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                sx={{ overflowY: "auto" }}
            >
                <Box
                    sx={{
                        borderRadius: "8px",
                        boxShadow: 1,
                        p: 2,
                        bgcolor: "background.paper",
                        transform: "translate(-50%, -50%)",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "100%",
                        maxWidth: 500,
                    }}
                >
                    <div style={{ fontSize: 18, fontWeight: "bold", marginBottom: 20 }}>
                        ຄົ້ນຫາຂໍ້ມູນ
                    </div>


                    <FormGroup>
                        <FormLabel>ຄົ້ນຫາຕາມຊື່</FormLabel>
                        <TextField
                            placeholder="ຄົ້ນຫາຕາມຊື່"
                            name="name"
                            value={byFullname}
                            size="small"
                            onChange={(e) => _handleKeyFullname(e)}
                        />
                    </FormGroup>
                    <div style={{ height: 10 }}></div>

                    <FormGroup>
                        <FormLabel>ຄົ້ນຫາຕາມເບີໂທ</FormLabel>
                        <TextField
                            placeholder="ຄົ້ນຫາຕາມເບີໂທ"
                            name="name"
                            value={byPhone}
                            size="small"
                            onChange={(e) => _handleKeyPhone(e)}

                        />
                    </FormGroup>
                    {/* <div style={{height: 10 }}></div>
                    <FormGroup>
                        <FormLabel>ຄົ້ນຫາຕາມວັນທີເລີ່ມຕົ້ນ</FormLabel>
                        <TextField
                            value={byDateStart}
                            size="small"
                            onChange ={(e)=> _handleKeyDateStart(e)}
                            type="date"
                        />
                    </FormGroup>
                    <div style={{height: 10 }}></div>
                    <FormGroup>
                        <FormLabel>ຄົ້ນຫາຕາມວັນທີສຸດທ້າຍ</FormLabel>
                        <TextField
                            value={byDateEnd}
                            size="small"
                            onChange ={(e)=> _handleKeyDateEnd(e)}
                            type="date"
                        />
                    </FormGroup>
                  
                   */}


                    <div style={{ height: 20 }}></div>



                    <div style={{ display: 'flex', justifyContent: "end" }}>
                        <button
                            style={{
                                background: "red",
                                color: "#ffffff",
                                width: 140,
                                height: 40,
                                border: '0',
                                outline: 'none',
                                fontSize: "16px",
                                fontWeight: "700",
                                borderRadius: 5,
                            }}
                            onClick={() => setPopSearch(false)}
                        >ປິດອອກ</button>
                        &nbsp;
                        <CustomButton title={"ຄົ້ນຫາ"} />
                    </div>
                </Box>
            </Modal>

        </Box>




    );
}
