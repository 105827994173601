import InviteFirend from "../pages/invite/InviteFirend"
import InviteAdd from "../pages/invite/form/InviteAdd"
import { Outlet } from "react-router-dom";

// eslint-disable-next-line
export default {
    path: "/",
    element: <Outlet />,
    children: [
      {
        path: "invite-friend",
        element: <InviteFirend />,
      },
      {
        path: "invite-add",
        element: <InviteAdd />,
      },
    ],
  };
  