import { Outlet } from "react-router-dom";
import OrderHistoryPage from "../pages/history/OrderHistoryPage"
import OrderHistoryDetail from "../pages/history/OrderHistoryDetail"

// eslint-disable-next-line
export default {
    path: "/",
    element: <Outlet />,
    children: [
      { 
        path:"order-history/limit/:limit/skip/:skip",
        element: <OrderHistoryPage />,
      },
      { 
        path:"order-history-detail",
        element: <OrderHistoryDetail />,
      },


    ]
    
  };
  