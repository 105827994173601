import axios from "axios";
import {BACKEND_ENPOINT} from "../constants";
// --------------------- //

const _ProductOptionsAPI = BACKEND_ENPOINT + "/product-options";
const _ProductOptionAPI = BACKEND_ENPOINT + "/product-option";
const _ProductOptionCreateAPI = BACKEND_ENPOINT + "/product-option";
const _ProductOptionUpdateAPI = BACKEND_ENPOINT + "/product-option";
const _ProductOptionDeleteAPI = BACKEND_ENPOINT + "/product-option";

export async function _ProductOptions(params = "?") {
  try {
    const data = await axios.get(_ProductOptionsAPI + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _ProductOption(_id, params = "?") {
  try {
    const data = await axios.get(_ProductOptionAPI + "/" + _id + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _ProductOptionCreate(value) {
  try {
    const data = await axios.post(_ProductOptionCreateAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _ProductOptionUpdate(_id, value) {
  try {
    const data = await axios.put(_ProductOptionUpdateAPI + "/" + _id, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _ProductOptionDelete(_id) {
  try {
    const data = await axios.delete(_ProductOptionDeleteAPI + "/" + _id);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}
