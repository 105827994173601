import { Outlet } from "react-router-dom";

import AccountDeshboard from "../pages/accounts/AccountDeshboard"
import AccountSetting from "../pages/accounts/AccountSetting"
import AccountList from "../pages/accounts/setting/account/AccountList"
import TypeList from "../pages/accounts/setting/typeIcomeAndExpend/TypeList";
import FormAddIncomeAndExpend from "../pages/accounts/incomeAndExpend/FormAdd";
import FormEditIncomeAndExpend from "../pages/accounts/incomeAndExpend/FormEdit";
import InComeAndExpendDetail from "../pages/accounts/mobile/InComeAndExpendDetail"

import Routes from "../constants/route"

// eslint-disable-next-line
export default {
    path: "/",
    element: <Outlet />,
    children: [
      { 
        path:Routes.ACCOUNT_DESHBOARD,
        element: <AccountDeshboard />,
      },
      { 
        path:Routes.ACCOUNT_SETTING,
        element: <AccountSetting />,
      },
      { 
        path:Routes.ACCOUNT_BANK_LIST,
        element: <AccountList />,
      },
      { 
        path:Routes.ACCOUNT_TYPE_LIST,
        element: <TypeList />,
      },
      { 
        path:Routes.ACCOUNT_ADD_INCOME_EXPEND,
        element: <FormAddIncomeAndExpend />,
      },
      { 
        path:Routes.ACCOUNT_EDIT_INCOME_EXPEND + "/:recordId",
        element: <FormEditIncomeAndExpend />,
      },
      { 
        path:Routes.ACCOUNT_DETAIL_INCOME_EXPEND + "/:recordId",
        element: <InComeAndExpendDetail />,
      }
    ]
    
  };
  
