import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import moment from 'moment';

import Routes from "../../../constants/route"
//api
import { _TypeAccounts, _Accounts, _IncomeAndExpendMonth } from "../../../apis"
import { useStore } from "../../../providers/useStore";
import { formatCurrency, convertTypeCurrency } from "../../../hooks/helpers"

//component
import BottomNavbar from './BottomNavbar'
import { URL_PHOTO_AW3 } from "../../../constants";
import { Loading } from "../../../components"


//mui
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';



export default function DeshboardMobile() {
  const navigate = useNavigate();
  const { storedData } = useStore();
  const startDate = moment().startOf("month").format("YYYY-MM-DD");
  const endDate = moment().endOf("month").format("YYYY-MM-DD");

  const [isLoading, setIsLoading] = useState(false)
  const [incomeAndExpendList, setIncomeAndExpendList] = useState([])
  const [incomeAndExpendTotal, setIncomeAndExpendTotal] = useState()

  const [selectCurrency, setSelectCurrency] = useState("KIP")

  //useEffect
  useEffect(() => {
    getDataIncomeAndExpend()
  }, [])

  useEffect(() => {
    getDataIncomeAndExpend(selectCurrency)
  }, [selectCurrency])

  //function
  const getDataIncomeAndExpend = async (_where) => {
    setIsLoading(true);
     let params = `?storeId=${storedData?.storeId}&currency=${selectCurrency}&dateAdd_gte=${startDate}&dateAdd_lt=${moment(endDate).add(1, 'days')}`;

    const result = await _IncomeAndExpendMonth(params);
    if (!result?.err) {
      setIncomeAndExpendList(result?.data?.report);
      setIncomeAndExpendTotal(result?.data?.total);
    }
    setIsLoading(false);
  };


  return (
    <div className='account-Mobile'>
      <div className='header'>
        <div className='header-left'>
          <p><CalendarMonthIcon />&nbsp;ປະຈຳເດືອນ {moment().format("MM-YYYY")}</p>
          <h2>{formatCurrency(incomeAndExpendTotal?.amount ?? 0)} {convertTypeCurrency(selectCurrency)}</h2>
          <h4>ລາຍຈ່າຍ: -{formatCurrency(incomeAndExpendTotal?.expend ?? 0)} {convertTypeCurrency(selectCurrency)}</h4>
          <h4>ລາຍໄດ້: +{formatCurrency(incomeAndExpendTotal?.income ?? 0)} {convertTypeCurrency(selectCurrency)}</h4>
        </div>
        <div className='header-right'>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">ສະກຸນເງິນ</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="ສະກຸນເງິນ"
              onChange={(e) => setSelectCurrency(e?.target?.value)}
            >
              <MenuItem value={"KIP"}>ກີບ</MenuItem>
              <MenuItem value={"BATH"}>ບາດ</MenuItem>
              <MenuItem value={"USD"}>ໂດລາ</MenuItem>
              <MenuItem value={"CNY"}>ຢວນ</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className='content'>
        <h3>ລາຍການຮັບ-ຈ່າຍ</h3>
        <AddCircleIcon onClick={() => navigate(Routes.ACCOUNT_ADD_INCOME_EXPEND)} style={{ color: "#4aa673", fontSize: "2.5rem", cursor: "pointer" }} />
      </div>

      <div className='body'>

        {isLoading ? <Loading /> : incomeAndExpendList?.map((item, index) =>
          <div className='card-list' key={index}>
            <div className='card-header'>
              <p>{item?.date}</p>
              <p>ລາຍຈ່າຍ: -{formatCurrency(item?.expend ?? 0)}</p>
              <p>ລາຍຮັບ: +{formatCurrency(item?.income ?? 0)}</p>
            </div>
            <div className='card-body'>
              {item?.data?.map((list, lIndex) =>
                <>
                  <div className='list' key={lIndex} onClick={() => navigate(Routes.ACCOUNT_DETAIL_INCOME_EXPEND + '/' + list?._id)}>
                    <div className='image'>
                      {!list?.image ? <ReceiptLongIcon style={{ width: 50, height: 50, borderRadius: "50%" }} /> :
                        <img src={URL_PHOTO_AW3 + '/' + list?.image} style={{ width: 50, height: 50, borderRadius: "50%" }} alt="img" />
                      }
                    </div>
                    <div className='card-content'>
                      <div className='list-title'>
                        <h4>{list?.typeIncomeAndExpendId?.title}</h4>
                        <h4 style={{ color: list?.type === "EXPEND" ? "red" : "green" }}>{list?.type === "EXPEND" ? "-" + formatCurrency(list?.qtyMoney ?? 0) : "+" + formatCurrency(list?.qtyMoney ?? 0)}</h4>
                      </div>
                      <p>{list?.note}</p>
                    </div>
                  </div>
                  {item.data.length - 1 === lIndex ? "" : <hr></hr>}
                </>
              )}
            </div>
          </div>
        )}

      </div>





      <BottomNavbar />
    </div>
  )
}
