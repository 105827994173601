import { useState } from "react";

export default function ProductOptionsState() {
  const [productOptionLoading, setProductOptionLoading] = useState();
  const [productOptions, setProductOptions] = useState();
  return {
    productOptionLoading,
    setProductOptionLoading,
    productOptions,
    setProductOptions,
  };
}
