import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import Routes from "../../../../constants/route"



import { useStore } from "../../../../providers/useStore";

//function
import {convertTypeCurrency} from "../../../../hooks/helpers"

//components
import { CustomButton, Title, Loading } from "../../../../components"
import FormAddAccount from "./component/FormAdd"
import FormEditAccount from './component/FormEdit';
import DeletePopup from "../../../../components/popup/DeletePopup";

//api
import { _Accounts,_AccountDelete } from "../../../../apis"

//mui
import 'react-toastify/dist/ReactToastify.css';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import MobileView from './component/MobileView';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AccountList() {
  const navigate = useNavigate();
  const { storedData } = useStore();


  //state
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [showFormEdit, setShowFormEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [accountList, setAccountList] = useState([])
  const [accountInfo, setAccountInfo] = useState()

  //useEffect
  useEffect(() => {
    getData()
  }, [])

  //function
  const getData = async () => {
    setIsLoading(true);

    let params = "?storeId=" + storedData?.storeId;

    const dataAccount = await _Accounts(params);
    if (!dataAccount?.err) {
      setAccountList(dataAccount?.data);
    }
    setIsLoading(false);
  };

  const notifyError = (text) => toast.error(text, { autoClose: 500, });
  const notifySuccess = (text) => toast.success(text, { autoClose: 500, });


  //delete
  const deleteAccount = async () => {
    setConfirmDelete(false);
   let result =  await _AccountDelete(accountInfo?._id);
   if(result?.err){
    notifyError('ບໍ່ສາມາດລຶບບັນຊີໄດ້');
    return
   }else{
    notifySuccess('ລຶບບັນຊີສຳເລັດ')
    setAccountInfo()
    getData();
   }
  };



  return (
    <>
      {/* <header>
        <title>Unitech-Store-{storeInfo?.name}-ເພີ່ມປະເພດສິນຄ້າ</title>
      </header> */}


      <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 20 }}>
        <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 10 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" onClick={() => navigate(Routes.ACCOUNT_DESHBOARD)} style={{ cursor: "pointer" }}>
                ລາຍການ
              </Link>
              <Typography underline="hover" color="inherit" onClick={() => navigate(Routes.ACCOUNT_SETTING)} style={{ cursor: "pointer" }}>ຈັດການຕັ້ງຄ່າ</Typography>
              <Typography color="text.primary">ຈັດການບັນຊີທະນາຄານ</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Title text={"ຈັດການບັນຊີທະນາຄານ"} />
          <CustomButton width={130} hidden={28} title="+ ເພີ່ມ"
            onClick={() => setShowFormAdd(true)}
          />
        </div>

        <p>ຈຳນວນ {accountList.length > 0 ? accountList.length : 0} ລາຍການ</p>


        {isLoading ? <Loading /> :
          <MobileView 
          accountList={accountList}
          setAccountInfo={setAccountInfo}
          setConfirmDelete={setConfirmDelete}
          setShowFormEdit={setShowFormEdit}
          />
        }

        <FormAddAccount showFormAdd={showFormAdd} setShowFormAdd={setShowFormAdd} fetchData={()=> getData()} />
        <FormEditAccount showFormEdit={showFormEdit}  accountInfo={accountInfo} clearInfo={()=>{setAccountInfo();setShowFormEdit(false) }} fetchData={()=> getData()} />

        <DeletePopup
         text={`ທ່ານຕ້ອງການລຶບບັນຊີ ${accountInfo?.accountName}(${convertTypeCurrency(accountInfo?.accountCurrency)}) ແທ້ ຫຼື ບໍ່?`}
         open={confirmDelete}
         onClose={() => setConfirmDelete(false)
         }
         onSubmit={deleteAccount}
        />
      </div>



    </>
  )
}

export default AccountList