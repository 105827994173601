// @mui
import { styled } from "@mui/material/styles";
import {

  Typography,

} from "@mui/material";
// sections
import LoginForm from "../components/form/LoginForm";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(assets/images/bg.jpg)",
    height:"100vh",
    backgroundPosition:"center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
}));


const StyledContent = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  width: {xs:"100%", lg:"40%"},
  padding: "30px",
  backgroundImage: "url(assets/images/bg.jpg)",
  height: "100vh",
 

}));


// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
     <header>
        <title>Unitech-ລ໋ອກອິນ</title>
      </header>
    <RootStyle>

      <StyledContent>
          <Typography variant="h4" gutterBottom style={{ textAlign: 'center', color: "#ffffff", fontWeight: "700", fontSize: 30, fontFamily: "NotoSansLao" }}>
            ເຂົ້າ​ສູ່​ລ​ະ​ບົບ
          </Typography>

          <Typography variant="body2" sx={{ mb: 5 }} style={{ textAlign: 'center', color: "#ffffff", fontWeight: "400", fontSize: 14, fontFamily: "NotoSansLao" }}>
            ປ້ອນ​ຂໍ້​ມູນ​ຂອງ​ທ່ານ​ໃຫ້​ລະ​ອຽດ
          </Typography>
          {/* ຟອມ */}
          <LoginForm />
          {/* ຟອມ */}
      </StyledContent>
    </RootStyle>
    </>

  );
}
