
/**
 * @react
 */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";


/**
 * @library
 */
import moment from 'moment'


/**
 * @constant
*/
import { PAGE_GINATION } from "../../../constants"



/**
 * @api
 */
import { _StockCreate, _Stocks, _StockDelete } from "../../../apis/Stock.service";
import { _Product } from "../../../apis/Products.service";

/**
 * @component
 */
import { CustomButton, Title, Loading } from "../../../components"
import FormImport from "./component/FormImport"
import DeletePopup from "../../../components/popup/DeletePopup";
import FormImportEdit from './component/FormImportEdit'
/**
 * @function
 */
import { formatDate, formatCurrency, convertTypeCurrency } from "../../../hooks/helpers"

/**
 * @css
 */
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Box, Grid, Divider, FormGroup,
    FormLabel, TextField, Link, Breadcrumbs, Typography
} from "@mui/material";



function ImportStock() {
    const navigate = useNavigate();
    const param = useParams()
    const productId = param.productId
    /**
  * state
  */
    const [showFormAdd, setShowFormAdd] = useState(false)
    const [showFormEdit, setShowFormEdit] = useState(false)
    const [expanded, setExpanded] = useState(false);
    const [select, setSelect] = useState();
    const [popDeleteImport, setPopDeleteImport] = useState(false);

    const [importList, setImportList] = useState([])
    const [importLoading, setImportLoading] = useState(false)

    const [productDetail, setProductDetail] = useState()
    const [importDetail, setImportDetail] = useState()

    const [filter, setFilter] = useState({})

    /**
   * useEffect
   */
    useEffect(() => {
        queryData()
    }, [])

    useEffect(() => {
        queryData(filter)
    },[filter])

    /**
     * function
     */
    const queryData = async (_filter) => {
        setImportLoading(true)
        let param = `?productId=${productId}`
        if (_filter?.dateImport_gte !== undefined && _filter?.dateImport_gte !== null && _filter?.dateImport_gte !== "" && _filter?.dateImport_lt !== undefined && _filter?.dateImport_lt !== null && _filter?.dateImport_lt !== "") {
            param = `?productId=${productId}&dateImport_gte=${_filter?.dateImport_gte}&dateImport_lt=${moment(filter?.dateImport_lt).add(1, 'days').format("YYYY-MM-DD")}`
        }

        const responseStore = await _Stocks(param)
        const responProduct = await _Product(productId);
        if (responseStore?.data !== null && responProduct?.data) {
            setImportList(responseStore?.data)
            setProductDetail(responProduct?.data)
            setImportLoading(false)
        }
    }

    //delete product
    const deleteImport = async () => {
        setPopDeleteImport(false);
        await _StockDelete(select?._id);
        queryData();
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 20 }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit"
                            onClick={() => navigate('/product' + PAGE_GINATION)} 
                            style={{ cursor: "pointer" }}>
                            ຈັດການສິນຄ້າ
                        </Link>
                        <Typography color="text.primary">ເພີ່ມສະຕ໋ອກ</Typography>
                    </Breadcrumbs>
                </div>

                <Title text={`ຊື່ສິນຄ້າ: ${productDetail?.name}`} />
                <Title text={`ຈຳນວນທັງໝົດ: ${formatCurrency(productDetail?.stock)}`} />

                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <FormGroup>
                            <FormLabel>ວັນທີເລີ່ມຕົ້ນ</FormLabel>
                            <TextField
                                onChange = {(e)=> setFilter({...filter, dateImport_gte:e?.target?.value})}
                                type="date"
                                size="small"
                                value={filter?.dateImport_gte}

                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <FormGroup>
                            <FormLabel>ວັນທີສຸດທ້າຍ</FormLabel>
                            <TextField
                                onChange = {(e)=> setFilter({...filter, dateImport_lt:e?.target?.value})}
                                type="date"
                                size="small"
                                value={filter?.dateImport_lt}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p>ຈຳນວນ 0 ລາຍການ</p>
                    <CustomButton width={130} hidden={28} title="+ ເພີ່ມ"
                        onClick={() => setShowFormAdd(!showFormAdd)}
                    />
                </div>

                {importLoading ? (
                    <>
                        <Loading />
                    </>
                ) : importList?.map((e, i) => (
                    <Accordion
                        key={i}
                        expanded={expanded === 'panel' + i}
                        onChange={handleChange('panel' + i)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >

                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div style={{ marginLeft: 20 }}>
                                    <p>ວັນທີນຳເຂົ້າ: {formatDate(e?.dateImport)}</p>
                                    <font style={{ color: "#6D6D6D", fontSize: 14 }}> ຈຳນວນເພີ່ມເຂົ້າ: {formatCurrency(e?.qty)}</font>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box style={{ display: "flex", gap: 10, justifyContent: "end" }}>
                                <span onClick={() => {setImportDetail(e);setShowFormEdit(true) }} style={{ textAlign: "center", borderRadius: "15px", backgroundColor: "#27B43E", color: "#FFFFFF", fontWeight: "500", padding: 5, width: 50, cursor: "pointer" }}>
                                    <EditIcon />
                                </span>
                                {" "}
                                <span onClick={() => {
                                    setSelect(e);
                                    setPopDeleteImport(true);
                                }} style={{ textAlign: "center", borderRadius: "15px", backgroundColor: "#FF0000", color: "#FFFFFF", fontWeight: "500", padding: 5, width: 50, cursor: "pointer" }}>
                                    <DeleteIcon />
                                </span>
                            </Box>
                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                            <div className="import-main-detail">
                                <p>ລາຄາ</p>
                                <p>{formatCurrency(e?.price)}</p>
                            </div>
                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                            <div className="import-main-detail">
                                <p>ສະກຸນເງິນ</p>
                                <p>{convertTypeCurrency(e?.currency)}</p>
                            </div>
                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                            <div className="import-main-detail">
                                <p>ລາຍລະອຽດສິນຄ້າ</p>
                                <p>{e?.detail ?? ''}</p>
                            </div>

                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                            <div className="import-main-detail">
                                <p>ວັນທີສ້າງ</p>
                                <p>{formatDate(e?.createdAt)}</p>
                            </div>

                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                            <div className="import-main-detail">
                                <p>ວັນທີແກ້ໄຂ</p>
                                <p>{formatDate(e?.updatedAt)}</p>
                            </div>

                        </AccordionDetails>
                    </Accordion>
                ))}

                <FormImport showFormAdd={showFormAdd} setShowFormAdd={setShowFormAdd} productId={productId} fetchData={() => queryData()} />
                <FormImportEdit showFormEdit={showFormEdit} setShowFormEdit={setShowFormEdit} productId={productId} fetchData={() => queryData()} importDetail={importDetail} setImportDetail={setImportDetail} />
                {/* popup */}
                < DeletePopup
                    text={"ທ່ານຕ້ອງການລຶບລາຍການນີ້ແທ້ ຫຼື ບໍ່?"}
                    open={popDeleteImport}
                    onClose={() => setPopDeleteImport(false)
                    }
                    onSubmit={deleteImport}
                />
            </div>
        </>
    )
}

export default ImportStock