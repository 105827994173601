import { Outlet } from "react-router-dom";
import ProductsPage from "../pages/product/ProductsPage";
import ProductTypePage from "../pages/product/ProductTypePage";
import ProductOptionPage from "../pages/product/ProductOptionPage";
import ColorsOptionPage from "../pages/product/ColorsOptionPage";
import ProductsAdd from "../pages/product/form/ProductAdd"
import ProductsEdit from "../pages/product/form/ProductionEdit"
import ImportStock from "../pages/product/stock/ImportStock"

import Routes from "../constants/route"

// eslint-disable-next-line
export default {
  path: "/",
  element: <Outlet />,
  children: [
    {
      path: "product/limit/:limit/skip/:skip",
      element: <ProductsPage />,
    },
    {
      path: "product-type",
      element: <ProductTypePage />,
    },
    {
      path: "product-option",
      element: <ProductOptionPage />,
    },
    {
      path: "color-option",
      element: <ColorsOptionPage />,
    },
    {
      path: "product-add",
      element: <ProductsAdd />,
    },
    {
      path: "product-edit/:productId",
      element: <ProductsEdit />,
    },
    {
      path: Routes.PRODUCT_IMPORT_STOCK + "/:productId",
      element: <ImportStock />,
    },
    
  ],
};
