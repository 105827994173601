import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import Routes from "../../../../constants/route"

import { useStore } from "../../../../providers/useStore";


//function
import { convertTypeAccount } from "../../../../hooks/helpers"

//components
import { CustomButton, Title, Loading } from "../../../../components"
import FormAddATypeIncomeAndExpend from "./component/FormAdd"
import FormEditATypeIncomeAndExpend from './component/FormEdit';
import MobileViewTypeAccount from './component/MobileView';
import DeletePopup from "../../../../components/popup/DeletePopup";

//api
import { _TypeAccounts, _TypeAccountDelete } from "../../../../apis"



//mui
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Breadcrumbs, Typography, Link } from '@mui/material';

function TypeList() {
  const navigate = useNavigate();
  const { storedData } = useStore();

  //state
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [showFormEdit, setShowFormEdit] = useState(false)

  const [filterType, setFilterType] = useState("EXPEND");

  
  const [isLoading, setIsLoading] = useState(false)
  const [typeAccountList, setTypeAccountList] = useState([])
  const [typeAccountInfo, setTypeAccountInfo] = useState()


  //useEffect
  useEffect(() => {
    getData(filterType)
  }, [filterType])

  //function
  const getData = async (filter) => {
    setIsLoading(true);

    let params = "?storeId=" + storedData?.storeId;

    if(filter){
        params = "?storeId=" + storedData?.storeId + "&type=" + filter;
    }
    const dataTypeAccount = await _TypeAccounts(params);
    if (!dataTypeAccount?.err) {
      setTypeAccountList(dataTypeAccount?.data);
    }
    setIsLoading(false);
  };

  const notifyError = (text) => toast.error(text, { autoClose: 500, });
  const notifySuccess = (text) => toast.success(text, { autoClose: 500, });


  //delete
  const deleteTypeAccount = async () => {
    setConfirmDelete(false);
    let result = await _TypeAccountDelete(typeAccountInfo?._id);
    if (result?.err) {
      notifyError('ບໍ່ສາມາດລຶບໄດ້');
      return
    } else {
      notifySuccess('ລຶບລາຍການສຳເລັດ')
      setTypeAccountInfo()
      getData();
    }
  };

  const _active={
    backgroundColor:"#4aa673",
    color:'#fff'
  }

  const _noActive={
    backgroundColor:"#eeeeee",
    color:'#000',
  }


  return (
    <>
      {/* <header>
        <title>Unitech-Store-{storeInfo?.name}-ເພີ່ມປະເພດສິນຄ້າ</title>
      </header> */}

      <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 20 }}>
        <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 10 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" onClick={() => navigate(Routes.ACCOUNT_DESHBOARD)} style={{ cursor: "pointer" }}>
                ລາຍການ
              </Link>
              <Typography underline="hover" color="inherit" onClick={() => navigate(Routes.ACCOUNT_SETTING)} style={{ cursor: "pointer" }}>ຈັດການຕັ້ງຄ່າ</Typography>
              <Typography color="text.primary">ຈັດການປະເພດຮັບ-ຈ່າຍ</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

          <Title text={"ຈັດການປະເພດຮັບ-ຈ່າຍ"} />
          <CustomButton width={130} hidden={28} title="+ ເພີ່ມ"
            onClick={() => setShowFormAdd(true)}
          />
        </div>

        <div className="typeAccountTab">
          <div className="left" style={filterType === "EXPEND" ? _active : _noActive} onClick={()=> setFilterType('EXPEND')}>ລາຍຈ່າຍ</div>
          &nbsp;
          <div className="rigth" style={filterType === "INCOME" ? _active : _noActive} onClick={()=> setFilterType('INCOME')}>ລາຍຮັບ</div>
        </div>

        <p>ຈຳນວນ {typeAccountList.length > 0 ? typeAccountList.length : 0} ລາຍການ</p>

        {isLoading ? <Loading /> :
          <MobileViewTypeAccount
            typeAccountList={typeAccountList}
            setConfirmDelete={setConfirmDelete}
            setTypeAccountInfo={setTypeAccountInfo}
            setShowFormEdit={setShowFormEdit}
          />
        }

        <FormAddATypeIncomeAndExpend showFormAdd={showFormAdd} setShowFormAdd={setShowFormAdd} fetchData={() => getData(filterType)} />
        <FormEditATypeIncomeAndExpend showFormEdit={showFormEdit} typeAccountInfo={typeAccountInfo} clearEdit={() => { setShowFormEdit(false); setTypeAccountInfo() }} fetchData={() => getData(filterType)} />


        <DeletePopup
          text={`ທ່ານຕ້ອງການລຶບບັນຊີ ${typeAccountInfo?.title} (${convertTypeAccount(typeAccountInfo?.type)}) ແທ້ ຫຼື ບໍ່?`}
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)
          }
          onSubmit={deleteTypeAccount}
        />
      </div>



    </>
  )
}

export default TypeList