import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import Routes from "../../../constants/route"
import { Lightbox } from "react-modal-image"
//api
import { _IncomeAndExpend,_IncomeAndExpendDelete } from "../../../apis"

//component
import DeletePopup from "../../../components/popup/DeletePopup";


//function
import { formatDate, formatCurrency, convertTypeCurrency, convertTypeAccount } from "../../../hooks/helpers"
import { URL_PHOTO_AW3 } from "../../../constants";
import { Loading } from "../../../components"

import {
    Link,
    Breadcrumbs,
    Typography,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function InComeAndExpendDetail() {
    const navigate = useNavigate();
    const param = useParams()
    const recordId = param.recordId

    const [isLoading, setIsLoading] = useState(false)
    const [detailData, setDetailData] = useState()
    const [showImage, setShowImage] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false);



    useEffect(() => {
        getProduct(recordId)
    }, [])

    const getProduct = async (_recordId) => {
        setIsLoading(true)
        const data = await _IncomeAndExpend(_recordId);
        if (data.data !== null) {
            setDetailData(data?.data)
            setIsLoading(false)
        }
    }

    const notifySuccess = (text) => toast.success(text, { autoClose: 500, });

    const handleDelete = async () => {
        await _IncomeAndExpendDelete(detailData?._id);
        await notifySuccess('ລຶບລາຍການສຳເລັດ')
        await navigate(Routes.ACCOUNT_DESHBOARD)
      };

    return (
        <div className='account-mobile-detail'>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" onClick={() => navigate(Routes.ACCOUNT_DESHBOARD)} style={{ cursor: "pointer" }}>
                    ລາຍການ
                </Link>
                <Typography color="text.primary">ບັນທຶກຮັບ-ຈ່າຍ</Typography>
            </Breadcrumbs>
            <br></br>
            {isLoading ? <Loading /> :
                <div className="detail-body">

                    <div className='image'>
                        {!detailData?.image ? <ReceiptLongIcon style={{ width: 100, height: 100, borderRadius: "50%" }} /> :
                            <img src={URL_PHOTO_AW3 + '/' + detailData?.image} onClick={() => setShowImage(!showImage)} style={{ width: 100, height: 100, borderRadius: "50%", cursor: "pointer" }} alt="img" />
                        }
                    </div>
                    <br></br>

                    <div className='content'>
                        <p>ວັນທີ</p>
                        <p>{formatDate(detailData?.dateAdd)}</p>
                    </div>
                    <hr></hr>
                    <div className='content'>
                        <p>ປະເພດທຸລະກຳ</p>
                        <p>{convertTypeAccount(detailData?.type)}</p>
                    </div>
                    <hr></hr>
                    <div className='content'>
                        <p>ເລກບິນ</p>
                        <p>{detailData?.billNo ?? '-'}</p>
                    </div>
                    <hr></hr>
                    <div className='content'>
                        <p>ສະກຸນເງິນ</p>
                        <p>{convertTypeCurrency(detailData?.currency ?? '-')}</p>
                    </div>
                    <hr></hr>
                    <div className='content'>
                        <p>ບັນຊີທຸລະກຳ</p>
                        <p>{detailData?.typeAccountId?.accountName ?? '-'}</p>
                    </div>
                    <hr></hr>
                    <div className='content'>
                        <p>ປະເພດທຸລະກຳ</p>
                        <p>{detailData?.typeIncomeAndExpendId?.title ?? '-'}</p>
                    </div>
                    <hr></hr>
                    <div className='content'>
                        <p>ຈຳນວນເງິນ</p>
                        <p>{formatCurrency(detailData?.qtyMoney ?? '-')}</p>
                    </div>
                    <hr></hr>
                    <div className='content'>
                        <p>ໝາຍເຫດ</p>
                        <p>{detailData?.note ?? '-'}</p>
                    </div>
                    <hr></hr>

                    <div className='manage'>
                        <button onClick={() => navigate(Routes.ACCOUNT_EDIT_INCOME_EXPEND + "/" + detailData?._id)} style={{ textAlign: "center", borderRadius: "15px", backgroundColor: "#27B43E", color: "#FFFFFF", fontWeight: "500", width: 100, border: 0, padding: 5, cursor: "pointer" }}>
                            <EditIcon />
                        </button>
                        &nbsp;
                        <button onClick={()=>setConfirmDelete(true)} style={{ textAlign: "center", borderRadius: "15px", backgroundColor: "#FF0000", color: "#FFFFFF", fontWeight: "500", width: 100, border: 0, padding: 5, cursor: "pointer" }}>
                            <DeleteIcon />
                        </button>
                    </div>
                </div>
            }

            {showImage && (

                <Lightbox
                    medium={URL_PHOTO_AW3 + '/' + detailData?.image}
                    large={URL_PHOTO_AW3 + '/' + detailData?.image}
                    alt={convertTypeAccount(detailData?.type)}
                    onClose={() => setShowImage(false)}
                />
            )}

            {/* popup */}
            <DeletePopup
                text={`ທ່ານຕ້ອງການລຶບແທ້ ຫຼື ບໍ່?`}
                open={confirmDelete}
                onClose={() => setConfirmDelete(false)}
                onSubmit={handleDelete}
            />

        </div>
    )
}

export default InComeAndExpendDetail