import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";


//constant


//components
import { CustomButton, TitleForm, Loading } from "../../../components"
import UploadPhoto from "../../../components/UploadImageMany";

//api
import {
    _InviteCreate,
} from "../../../apis/InviteUser.service";
import { useStore } from "../../../providers/useStore";

//mui
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '@mui/material/styles';
import {
    FormGroup,
    FormLabel,
    TextField,
    FormControl,
    MenuItem,
    Select,
    Button,
    Link,
    Breadcrumbs,
    Typography,
    Grid
} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function InviteAdd() {
    const navigate = useNavigate();
    const { storedData } = useStore();

    // state

    // functions
    const notifyError = (text) => toast.error(text, { autoClose: 1000, });
    const notifyWarning = (text) => toast.warning(text, { autoClose: 1000, });
    const notifySuccess = (text) => toast.success(text, { autoClose: 1000, });


    const addInviteUser = async (e) => {
        const data = await _InviteCreate(e);
        if (!data?.err) {
            await notifySuccess('ເພີ່ມລາຍການສຳເລັດ')
            setTimeout(() => {
                navigate('/invite-friend')
            }, 1000);
        }
        else if (data?.message?.response?.data === "YOUR_PHONE_IS_ALREADY_USE") notifyWarning(`ເບີໂທນີ້ ${e.phone} ໄດ້ຖືກນຳໃຊ້ແລ້ວ`)

        else notifyError("ເພີ່ມບໍ່ສຳເລັດ")
    };


    //validate 
    const val = () => {
        return <span style={{ color: "red" }}>*</span>
    }


    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 20 }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" onClick={() => navigate('/product')}>
                            ຈັດການສິນຄ້າ
                        </Link>
                        <Typography color="text.primary">ຟອມເພີ່ມສິນຄ້າ</Typography>
                    </Breadcrumbs>
                </div>
                <TitleForm text={"ຟອມເຊີນໝູ່"} />
                {/* ຟອມເພີ່ມສິນຄ້າ    */}
                <Formik
                    initialValues={{
                        name: "",
                        whatsapp: "",
                        address: "",
                        password: "",
                        phone: "",
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.name) errors.name = "ກະລຸນາຕື່ມຊື່ສິນຄ້າ"
                        if (!values.whatsapp) errors.whatsapp = "ກະລຸນາຕື່ມເບີໂທວອດແອັບ"
                        if (!values.phone) errors.phone = "ກະລຸນາຕື່ມເບີໂທ"
                        if (!values.password) errors.password = "ກະລຸນາຕື່ມລະຫັດຜ່ານ"
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {

                        let _data = {
                            ...values,
                            contacts: {
                                whatsapp: values.whatsapp,
                                phone: values.phone,
                            },
                            address: values.address,
                            status: "WAITING",
                            inviter: storedData?.storeId
                        }

                        delete values.whatsapp
                        delete values.phone


                        addInviteUser(_data)
                        setSubmitting(false);

                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setSubmitting
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 10,
                                }}
                            >
                                <FormGroup>
                                    <FormLabel>ຊື່ຮ້ານ {val()}</FormLabel>
                                    <TextField
                                        error={!!errors.name}
                                        placeholder="ຊື່ຮ້ານ"
                                        name="name"
                                        value={values?.name}
                                        onChange={handleChange}
                                        maxLength="5"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormLabel>ເບີ whatsapp {val()}</FormLabel>
                                    <TextField
                                        error={!!errors.whatsapp}
                                        placeholder="ປ້ອນເບີ whatsapp"
                                        name="whatsapp"
                                        value={values?.whatsapp}
                                        onChange={handleChange}
                                        type="number"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>ເບີໂທ(ນຳໃຊ້ເຂົ້າລະບົບ) {val()}</FormLabel>
                                    <TextField
                                        placeholder="ປ້ອນເບີໂທ"
                                        name="phone"
                                        value={values?.phone}
                                        onChange={handleChange}
                                        type="number"
                                        error={!!errors.phone}

                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormLabel>ລະຫັດຜ່ານເຂົ້າລະບົບ {val()}</FormLabel>
                                    <TextField
                                        error={!!errors.password}
                                        placeholder="ລະຫັດຜ່ານ"
                                        name="password"
                                        value={values?.password}
                                        onChange={handleChange}
                                        type="text"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormLabel>ທີ່ຢູ່ຮ້ານ</FormLabel>
                                    <TextField
                                        name="address"
                                        value={values?.address}
                                        onChange={handleChange}
                                        placeholder="ລາຍລະອຽດສິນຄ້າ..."
                                        multiline
                                        InputProps={{ style: { minHeight: 100, alignItems: "start" } }}

                                    />
                                </FormGroup>

                                <Button
                                    type="submit"
                                    onClick={handleSubmit}
                                    variant="contained"
                                    sx={{ boxShadow: 0, fontWeight: "700" }}
                                    size="large"
                                >
                                    ບັກທຶກ
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>

                {/* ຟອມເພີ່ມສິນຄ້າ    */}

            </div>

            <ToastContainer />

        </>
    );
}
