import axios from "axios";
import {BACKEND_ENPOINT} from "../../constants";
// --------------------- //

const _AccountsAPI = BACKEND_ENPOINT + "/typeAccounts";
// const _AccountAPI = BACKEND_ENPOINT + "/color";
const _AccountCreateAPI = BACKEND_ENPOINT + "/create-typeAccount";
const _AccountUpdateAPI = BACKEND_ENPOINT + "/update-typeAccount";
const _AccountDeleteAPI = BACKEND_ENPOINT + "/delete-typeAccount";

export async function _Accounts(params = "?") {
  try {
    const data = await axios.get(_AccountsAPI + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

// export async function _Account(_id, params = "?") {
//   try {
//     const data = await axios.get(_AccountAPI + "/" + _id + params);
//     if (data.status < 300) {
//       return { err: false, data: data.data };
//     }
//     return { err: true, message: `err - ${data.status}` };
//   } catch (err) {
//     console.log("err", err);
//     return { err: true, message: err };
//   }
// }

export async function _AccountCreate(value) {
  try {
    const data = await axios.post(_AccountCreateAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _AccountUpdate(_id, value) {
  try {
    const data = await axios.put(_AccountUpdateAPI + "/" + _id, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _AccountDelete(_id) {
  try {
    const data = await axios.delete(_AccountDeleteAPI + "/" + _id);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}
