import React from "react";
import { Context } from "./Context";
import AuthState from "./globalState/auth.state";
import ProductsState from "./globalState/products.state";
import ProductTypesState from "./globalState/productTypes.state";
import ColorsOptionState from "./globalState/ColorsOptionState.state";
import ProductOptionsState from "./globalState/productOptions.state";
import AdssState from "./globalState/adss.state";
import OrderState from "./globalState/orders.state"


export const StateProvider = ({ children }) => {
  const auth = AuthState();
  const products = ProductsState();
  const productTypes = ProductTypesState();
  const productOptions = ProductOptionsState();
  const colorsOptionPage = ColorsOptionState();
  const adssState = AdssState();
  const Orders= OrderState();
  let store = Object.assign(
    auth,
    products,
    productTypes,
    productOptions,
    adssState,
    colorsOptionPage,
    Orders
  );
  return <Context.Provider value={store}>{children}</Context.Provider>;
};
