import axios from "axios";
import {BACKEND_ENPOINT} from "../constants";
// --------------------- //

const _ProductTypesAPI = BACKEND_ENPOINT + "/product-types";
const _ProductTypeAPI = BACKEND_ENPOINT + "/product-type";
const _ProductTypeCreateAPI = BACKEND_ENPOINT + "/product-type";
const _ProductTypeUpdateAPI = BACKEND_ENPOINT + "/product-type";
const _ProductTypeDeleteAPI = BACKEND_ENPOINT + "/product-type";

export async function _ProductTypes(params = "?") {
  try {
    const data = await axios.get(_ProductTypesAPI + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _ProductType(_id, params = "?") {
  try {
    const data = await axios.get(_ProductTypeAPI + "/" + _id + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _ProductTypeCreate(value) {
  try {
    const data = await axios.post(_ProductTypeCreateAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _ProductTypeUpdate(_id, value) {
  try {
    const data = await axios.put(_ProductTypeUpdateAPI + "/" + _id, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _ProductTypeDelete(_id) {
  try {
    const data = await axios.delete(_ProductTypeDeleteAPI + "/" + _id);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}
