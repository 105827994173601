import { useState } from "react";

export default function ProductTypesState() {
  const [productTypeLoading, setProductTypeLoading] = useState();
  const [productTypes, setProductTypes] = useState();
  return {
    productTypeLoading,
    setProductTypeLoading,
    productTypes,
    setProductTypes,
  };
}
