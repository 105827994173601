import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

//mui
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import SettingsIcon from '@mui/icons-material/Settings';

import Routes from "../../../constants/route"


function BottomNavbar() {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3} className='bottomNavbar'>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                <BottomNavigationAction label="ລາຍການ" icon={<ReceiptLongIcon />} />
                <BottomNavigationAction label="ລາຍງານ" icon={<PieChartOutlineIcon />} />
                <BottomNavigationAction label="ຄົ້ນຫາ" icon={<ContentPasteSearchIcon />} />
                <BottomNavigationAction label="ຕັ້ງຄ່າ" onClick={()=> navigate(Routes.ACCOUNT_SETTING)} icon={<SettingsIcon />} />

            </BottomNavigation>
        </Paper>
    )
}

export default BottomNavbar