import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useStore } from "../../providers/useStore";
import AddColorPopup from "../../components/popup/AddColorPopup";
import EditColorPopup from "../../components/popup/EditColorPopup";
import DeletePopup from "../../components/popup/DeleteColorPopup";

//function


//components
import { CustomButton, Title, Loading } from "../../components"


import {
  _ColorCreate,
  _ColorUpdate,
  _ColorDelete,
  _Colors,
} from "../../apis/Color.service";
import { _StoresReport, _Store } from "../../apis/Stores.service"



//mui
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export default function ColorsOptionPage() {
  // state
  const { colorLoading, setColorLoading } = useStore();
  const { colorList, setColorList } = useStore();
  const [select, setSelect] = useState();
  const { storedData } = useStore();

  const [storeInfo, setStoreInfo] = useState()
  const [popAddColor, setPopAddColor] = useState(false);
  const [popEditColor, setPopEditColor] = useState(false);
  const [popDeleteColor, setPopDeleteColor] = useState(false);

  //useEffect
  useEffect(() => {
    if (storedData) 
    getData(storedData);
    getStoreInfo(storedData)

  }, [storedData]);

  // functions

  const getStoreInfo = async (storedData) => {
    const param = storedData?.storeId
    const responseStore = await _Store(param)
    if (responseStore?.data !== null) {
        setStoreInfo(responseStore?.data)
    }
}

  const notifyError = (text) => toast.error(text, { autoClose: 1000, });
  const notifyWarning = (text) => toast.warning(text, { autoClose: 1000, });
  const notifySuccess = (text) => toast.success(text, { autoClose: 1000, });

  const addColor = async (e) => {
    setPopAddColor(false);
    const data = await _ColorCreate(e);
    if (!data?.err) {
      notifySuccess("ເພີ່ມສຳເລັດ")
      getData(storedData);
    }
    else if (data?.message?.response?.data === "THIS_COLOR_ALREADY") notifyWarning(`ທ່ານໄດ້ເພີ່ມສີ ${e.name} ແລ້ວ`)
    else notifyError("ເພີ່ມບໍ່ສຳເລັດ")
    getData(storedData);
  };
  const updateColor = async (e) => {
    setPopEditColor(false);
    const data = await _ColorUpdate(e?._id, e);
    if (!data?.err) {
      notifySuccess("ແກ້ໄຂສຳເລັດ")
      getData(storedData);
    }
    else notifyError("ແກ້ໄຂບໍ່ສຳເລັດ")
    getData(storedData);
  };
  const deleteColor = async () => {
    setPopDeleteColor(false);
    const data = await _ColorDelete(select?._id);
    if (!data?.err) {
      notifySuccess("ລຶບລາຍການສຳເລັດ")
      getData();
    }
    else notifyError("ບໍ່ສາມາດລຶບໄດ້")
    getData(storedData);

  };

  const getData = async (storedData) => {
    setColorLoading(true);
    let params = "?storeId=" + storedData?.storeId;
    const data = await _Colors(params);
    if (!data?.err) {
      setColorList(data?.data);
    }
    setColorLoading(false);
  };

  return (
    <>
      <header>
        <title>Unitech-Store-{storeInfo?.name}-ເພີ່ມສີ</title>
      </header>

      <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 20 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>

          <Title text={"ຈັດການສີສິນຄ້າ"} />
          <CustomButton width={130} hidden={28} title="+ ເພີ່ມ" onClick={() => setPopAddColor(true)} />
        </div>

        {colorLoading ? (
          <>
            <Loading />
          </>
        ) : (

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">ລຳດັບ</TableCell>
                  <TableCell align="center">ເນື້ອໃນ</TableCell>
                  {/* <TableCell align="center">ວັນທີສ້າງ</TableCell> */}
                  <TableCell align="center">ຈັດການ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {colorList?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">
                      {row.name}
                    </TableCell>
                    {/* <TableCell align="center">{formatDate(row.createdAt)}</TableCell> */}
                    <TableCell align="center">

                      <Box style={{ display: "flex", gap: 10, justifyContent: "center" }}>

                        <EditIcon onClick={() => {
                          setSelect(row);
                          setPopEditColor(true);
                        }}
                          style={{ color: "#27B43E" }}
                        />
                        {" "}
                        <DeleteIcon onClick={() => {
                          setSelect(row);
                          setPopDeleteColor(true);
                        }}
                          style={{ color: "#FF0000" }}
                        />
                      </Box>

                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        )}
      </div>
      {/* popup */}
      <AddColorPopup
        open={popAddColor}
        onClose={() => setPopAddColor(false)}
        onSubmit={addColor}
      />
      <EditColorPopup
        value={select}
        open={popEditColor}
        onClose={() => setPopEditColor(false)}
        onSubmit={updateColor}
      />
      <DeletePopup
        text={select?.name}
        open={popDeleteColor}
        onClose={() => setPopDeleteColor(false)}
        onSubmit={deleteColor}
      />
      <ToastContainer />

    </>
  );
}
