import React, { useEffect, useState } from "react";
import { useStore } from "../../providers/useStore";
import { useNavigate } from "react-router-dom";

//function
import { formatDate } from "../../hooks/helpers"

//components
import { Title } from "../../components"
import Pagination from "../../hooks/helpers/PaginationHelper";

//apis
import { _Orders } from "../../apis/Orders.service"
import { _StoresReport, _Store } from "../../apis/Stores.service"

// ui
import { ToastContainer } from 'react-toastify';
import {
    Box, Grid, FormGroup,
    TextField,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';


export default function OrderHistoryPage() {

    const navigate = useNavigate();

    const { storedData } = useStore();
    const { orderLoading, setOrderLoading, orders, setOrders } = useStore();
    const { _limit, _skip, Pagination_helper } = Pagination();
    const [ordersTotal, setOrdersTotal] = useState(0)

    //state
    //filter
    const [storeInfo, setStoreInfo] = useState()

    const [byFullname, setByFullname] = useState("");
    const [byPhone, setByPhone] = useState("");


    useEffect(() => {
        getStoreInfo(storedData)
    }, [storedData])

    const getStoreInfo = async (storedData) => {
        const param = storedData?.storeId
        const responseStore = await _Store(param)
        if (responseStore?.data !== null) {
            setStoreInfo(responseStore?.data)
        }
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [byFullname, byPhone, _limit, _skip])// eslint-disable-next-line react-hooks/exhaustive-deps

    const getData = async () => {
        setOrderLoading(true)
        let params = "?limit=" + _limit + "&skip=" + _skip + "&storeId=" + storedData?.storeId;
        if (byFullname !== "") params = "?storeId=" + storedData?.storeId + "&fullname=" + byFullname
        if (byPhone !== "") params = "?storeId=" + storedData?.storeId + "&phone=" + byPhone
        if (byFullname !== "" && byPhone !== "") params = "?storeId=" + storedData?.storeId + "&fullname=" + byFullname + "&phone=" + byPhone

        const data = await _Orders(params);
        if (!data?.err) {
            setOrders(data?.data?.data);
            setOrdersTotal(data?.data?.total);
        }
        setOrderLoading(false)
    }

    const _handleKeyFullname = (e) => {
        setByFullname(e?.target?.value)
    }
    const _handleKeyPhone = (e) => {
        setByPhone(e?.target?.value)
    }

    const skeleton = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    return (
        <>
            <header>
                <title>Unitech-Store-{storeInfo?.name}-ປະຫວັດສັ່ງຊື້</title>
            </header>
            <Box container spacing={2} style={{ padding: 20 }}>


                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
                    <Title text={"ປະຫວັດການສັ່ງຊື້"} />
                </div>


                <Grid container spacing={2} style={{ marginBottom: 10 }}>
                    <Grid item xs={6}>
                        <FormGroup>
                            <p>ຄົ້ນຫາຕາມຊື່</p>
                            <TextField
                                placeholder="ຄົ້ນຫາຕາມຊື່"
                                name="name"
                                value={byFullname}
                                size="small"
                                onChange={(e) => _handleKeyFullname(e)}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <FormGroup>
                            <p>ຄົ້ນຫາຕາມເບີໂທ</p>
                            <TextField
                                placeholder="ຄົ້ນຫາຕາມເບີໂທ"
                                name="name"
                                value={byPhone}
                                size="small"
                                onChange={(e) => _handleKeyPhone(e)}

                            />
                        </FormGroup>
                    </Grid>

                </Grid>



                <List sx={{ width: '100%', maxWidth: "100%", bgcolor: 'background.paper' }}>
                    {orderLoading ? skeleton?.map((ske) =>
                        <>
                            <ListItem alignItems="flex-start" sx={{ width: '100%', maxWidth: "100%", bgcolor: 'background.paper' }}>
                                <ListItemAvatar>
                                    <Skeleton variant="circular" width={50} height={50} />
                                </ListItemAvatar>
                                <Stack spacing={1}>
                                    <Skeleton variant="text" width={260} sx={{ fontSize: '1rem', }} />
                                    <Skeleton variant="text" width={260} sx={{ fontSize: '1rem', }} />
                                    <Skeleton variant="text" width={260} sx={{ fontSize: '1rem', }} />
                                    <Skeleton variant="text" width={260} sx={{ fontSize: '1rem', }} />
                                </Stack>
                            </ListItem>
                            <Divider variant="inset" component="li" />

                        </>
                    ) : orders?.map((order, index) =>
                        <>
                            <ListItem alignItems="flex-start" key={index} >
                                <ListItemAvatar style={{ fontFamily: "NotoSansLao" }}>
                                    <Avatar style={{ fontFamily: "NotoSansLao" }} alt={order?.fullname} src="/static/images/avatar/2.jpg" />
                                </ListItemAvatar>
                                <ListItemText
                                    style={{ fontFamily: "NotoSansLao" }}
                                    primary={order?.fullname}
                                    secondary={
                                        <React.Fragment>
                                            <p
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                ເບີໂທ: {order?.phone}
                                            </p>
                                            <p
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                ສະຖານທີ່ສົ່ງ: {order?.address}
                                            </p>

                                            <p
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                ວັນທີສັ່ງ:{formatDate(order?.createdAt)}
                                            </p>

                                            <p
                                                style={{ display: 'inline', color: "blue", cursor: "pointer" }}
                                                onClick={() => navigate('/order-history-detail', { state: order })}
                                            >
                                                ເບິ່ງລາຍລະອຽດ
                                            </p>


                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </>
                    )}

                </List>

                {ordersTotal <= 0 ? "" : (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                        {Pagination_helper(ordersTotal, "/order-history")}
                    </div>
                )}



                <ToastContainer />


            </Box>

        </>



    );
}
