/**
 * @react
 */
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

/**
 * @library
 */



/**
 * @constant
*/



/**
 * @apollo
 */



/**
 * @component
 */
import ChangePassword from "./component/ChangePassword"


/**
 * @function
 */



/**
 * @css
 */
import { ToastContainer, toast } from 'react-toastify';


import { Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

//icon
import CategoryIcon from '@material-ui/icons/Category';
import PasswordIcon from '@mui/icons-material/Password';
import PaletteIcon from '@material-ui/icons/Palette';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    padding: 10,
    cursor: "pointer"
}));

function SettingPage() {
    const navigate = useNavigate();
    /**
     * state
     */
    const [showFormChangePassword, setShowFormChangePassword] = useState(false)

    /**
     * function
     */
    //close form change password
    const handleCloseFormChangePassword = () => setShowFormChangePassword(false)

    return (
        <>
            <header>
                <title>Unitech-Store-ຕັ້ງຄ່າ</title>
            </header>

            <Grid container spacing={1} style={{ padding: 5, marginTop:10 }} >

                <Grid item xs={4} sm={4} md={2} lg={2} onClick={() => navigate('/product-type')}>
                    <Item>
                        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <CategoryIcon style={{ fontSize: 64, color: "#1AB17B", fontWeight: "400" }} />
                            <font style={{ fontSize: 14, color: "#6D6D6D", fontWeight: "400" }}>ເພີ່ມປະເພດສິນຄ້າ</font>
                        </div>
                    </Item>
                </Grid>


                <Grid item xs={4} sm={4} md={2} lg={2} onClick={() => navigate('/color-option')}>
                    <Item>
                        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <PaletteIcon style={{ fontSize: 64, color: "#1AB17B", fontWeight: "400" }} />
                            <font style={{ fontSize: 14, color: "#6D6D6D", fontWeight: "400" }}>ເພີ່ມສີ</font>
                        </div>
                    </Item>
                </Grid>

                <Grid item xs={4} sm={4} md={2} lg={2} onClick={() => setShowFormChangePassword(true)}>
                    <Item>
                        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <PasswordIcon style={{ fontSize: 64, color: "#1AB17B", fontWeight: "400" }} />
                            <font style={{ fontSize: 14, color: "#6D6D6D", fontWeight: "400" }}>ປ່ຽນລະຫັດຜ່ານ</font>
                        </div>
                    </Item>
                </Grid>


            </Grid>


            {/* component */}
            <ChangePassword showFormChangePassword={showFormChangePassword} handleCloseFormChangePassword={() => handleCloseFormChangePassword()} />

            <ToastContainer />
        </>
    )
}

export default SettingPage