import React from 'react'
import AddIcon from '@mui/icons-material/Add';
/**
 *
 * @param {confirm} : show confirm
 * @param {addIcon} : show addIcon
 * @param {angleDoubleUp} : show angleDoubleUp
 * @param {downloadIcon} : show downloadIcon
 */
function CustomButton({ title, onClick,eyeIcon, confirm, addIcon, angleDoubleUp, downloadIcon, editIcon, searchIcon, width, height, style, disabled,deleteIcon}) {
  return (
    <button
      type="button"
      style={{
        background: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)",
        color: "#ffffff",
        width: width || 140,
        height: height || 40,
        border: '0',
        outline: 'none',
        fontSize:"16px",
        fontWeight:"700",
        borderRadius: 5,
        ...style
      }}
      disabled={disabled ?? false}
      onClick={() => onClick()}
    >
       {/* {addIcon && <AddIcon />} */}
      {title}

      {/* {angleDoubleUp && <i className='fa fa-angle-double-up' />}{' '}
      {downloadIcon && <i className='fa fa-download' />} {title}
      {editIcon && <i className='fa fa-edit' />}
      {searchIcon && <i className='fa fa-search' />}
      {deleteIcon && <i className='fa fa-trash' />}
      {eyeIcon && <i className='fa fa-eye' />}  */}

    </button>
  )
}

export default CustomButton
