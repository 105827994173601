import React, { useState } from 'react'
import { URL_PHOTO_AW3, PRESIGNED_URL_MANY } from "../constants";

import axios from 'axios'
import { Badge } from "@material-ui/core";
import Grid from '@mui/material/Grid';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const UploadPhoto = () => {

    const [namePhotoMany, setNamePhotoMany] = useState([])
    const [imageLoadingMany, setImageLoadingMany] = useState()


    // const [widhtPhotoFamily, setWidhtPhotoFamily] = useState(100)
    const [heightPhotoFamily, setHeightPhotoFamily] = useState(100)


    const handleUploadMany = async (event) => {
        setImageLoadingMany();

        try {

            let _newType = []

            for (let i = 0; i < event.target.files.length; i++) {
                _newType.push({
                    name: event.target.files[i].name,
                    type: event.target.files[i].type,
                })
            }

            const responseUrl = await axios({
                method: "post",
                url: PRESIGNED_URL_MANY,
                data: _newType,
            });

            let _newImageName = [...namePhotoMany]
            const _authorization = axios.defaults.headers.common["Authorization"];
            delete axios.defaults.headers.common["Authorization"];
            let newResponse = [];

            for (var j = 0; j < responseUrl.data.length; j++) {
               
                    const response = await axios({
                        method: "put",
                        url: responseUrl.data[j].url,
                        data: event.target.files[j],
                        headers: {
                            "Content-Type": " file/*; image/*",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                        onUploadProgress: function (progressEvent) {
                            var percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            )
                            setImageLoadingMany(percentCompleted)
                        }
                    },
                    );
                    newResponse.push(response);

                _newImageName.push(responseUrl.data[j].params.Key)

            }

            axios.defaults.headers.common["Authorization"] = _authorization;

            setNamePhotoMany(_newImageName)



        } catch (error) {
            console.log("error", error);
        }
    };


    const _onDeleteFile = (item) => {
        let filnameData = namePhotoMany?.filter((data) => data !== item)
        setNamePhotoMany(filnameData)
    }

    const buttonUploadAndShowPhotoMany = () => {
        return (
            <Grid container spacing={2}>

                {namePhotoMany?.map((namePhotoMany, index) => {
                    return (
                        <Grid item xs={4} sm={3} md={1} lg={1} key={index}>

                            <Badge badgeContent="X" color="secondary" style={{ backgroundColor: "#ffffff", width: "100%" }} onClick={() => _onDeleteFile(namePhotoMany)}>

                                <img
                                    src={URL_PHOTO_AW3 + "/" + namePhotoMany}
                                    alt="Unimarket"
                                    style={{
                                        padding: 0,
                                        margin: 0,
                                        height: heightPhotoFamily,
                                        width: "100%",
                                        borderRadius: 5,
                                        backgroundColor: "none"
                                    }}
                                />
                            </Badge>
                        </Grid>

                    )
                })}

                {/* </div> */}

                {/* <div> */}
                <Grid item xs={4} sm={2} md={1} lg={1}>
                    <input
                        type='file'
                        id='file-upload-many'
                        onChange={handleUploadMany}
                        hidden
                        multiple
                    />
                    <label htmlFor='file-upload-many' style={{
                        padding: 0,
                        margin: 0,

                    }}>
                        <div
                            style={{
                                height: heightPhotoFamily,
                                width: "100%",
                                borderRadius: 5,
                                cursor: 'pointer',
                                display: 'flex',
                                backgroundColor: '#F5F5F5',
                                padding: 0,
                                margin: 0,
                                borderColor: "#3BEEAE",
                                borderStyle: "dashed"
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    height: heightPhotoFamily,
                                    width: "100%",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 0,
                                    margin: 0,
                                    borderRadius: 5,

                                }}
                            >
                                <FileUploadIcon />
                            </div>
                        </div>
                    </label>
                    {/* progass */}
                    {imageLoadingMany ? (
                        <div className='progress' style={{ height: 20 }}>
                            <div
                                className='progress-bar'
                                role='progressbar'
                                style={{
                                    width: `${imageLoadingMany}%`,
                                    backgroundColor: 'green'
                                }}
                                aria-valuenow={imageLoadingMany}
                                aria-valuemin='0'
                                aria-valuemax='100'
                            >
                                {imageLoadingMany}%
                            </div>
                        </div>
                    ) : (
                        <div style={{ height: 20 }} />
                    )}
                </Grid>
                {/* </div> */}

            </Grid>
        )
    }



    return {
        namePhotoMany,
        buttonUploadAndShowPhotoMany,
        setNamePhotoMany,
    }
}
export default UploadPhoto
