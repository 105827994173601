import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui

import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,

} from "@mui/material";
import PhoneIcon from '@material-ui/icons/Phone';
import LockIcon from '@material-ui/icons/Lock';
// import { LoadingButton } from '@mui/lab';

// icons
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

// apis
import { _Login } from "../../apis";
import { useStore } from "../../providers";

// ----------------------------------------------------------------------

export default function LoginForm() {

  const navigate = useNavigate();
  const { setToken } = useStore();
  const { setStoredData } = useStore();

  // state
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = () => {

    _Login({ username, password }).then((e) => {
      setToken(e?.data?.accessToken);
      setStoredData(e?.data?.data)
      navigate("/home", { replace: true });
    });
  };

  const onContactSupport = ()=>{
    window.location.href = `https://wa.me/+8562056891992/?text=ສະບາຍດີ`;
  }

  return (
    <>
   
      <Stack spacing={3}>
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position="start" style={{ color: "#ffffff" }}><PhoneIcon /> | +85620</InputAdornment>,
          }}
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <TextField
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? "text" : "password"}
          InputProps={{
            startAdornment: <InputAdornment position="start" style={{ color: "#ffffff" }}><LockIcon /></InputAdornment>,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                  style={{ color: "#ffffff" }}
                >
                  {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Box>
          <Checkbox name="remember" id="remember" />
          <label htmlFor="remember">ຈົດຈຳການເຂົ້າລະບົບ</label>
        </Box>
        <Link variant="subtitle2" underline="hover">
          ລືມລະຫັດຜ່ານ?
        </Link>
      </Stack> */}

      <button
        type="submit"
        onClick={handleLogin}
        style={{fontSize:16,fontWeight:700,color:"#ffffff", marginTop:20,cursor: "pointer",background: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)",padding:20,border:0, borderRadius: "15px"}}
      >
        ເຂົ້າສູ່ລະບົບ
      </button>

      <p style={{ fontSize: "14px",marginTop: 50, textAlign: "center",color:"#ffffff"}}>
      ​ລືມ​ລະ​ຫັດ​ຜ່ານ? ​<span style={{color:"#ffffff",fontWeight:"700", cursor: "pointer" }} onClick ={()=> onContactSupport()}><u>ຕິດ​ຕໍ່​ຜູ້​ໃຫ້​ບໍ​ລິ​ການ</u></span>
      </p>
    </>
  );
}
