import axios from "axios";
import {BACKEND_ENPOINT} from "../constants";

// --------------------- //

const _StoresAPI = "";
const _StoreAPI = BACKEND_ENPOINT+ "/store";
const _StoreCreateAPI = "";
const _StoreUpdateAPI = BACKEND_ENPOINT+"/store-update";
const _StoreChangePasswordAPI = BACKEND_ENPOINT+"/store-change-password";
const _StoreDeleteAPI = "";
const _StoreReportAPI = BACKEND_ENPOINT+ "/report";

export async function _Stores(params = "?") {
  try {
    const data = await axios.get(_StoresAPI + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _StoresReport(params = "?") {
  try {
    const data = await axios.get(_StoreReportAPI + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}




export async function _Store(_id, params = "?") {
  try {
    const data = await axios.get(_StoreAPI + "/" + _id );
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _StoreCreate(value) {
  try {
    const data = await axios.post(_StoreCreateAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _StoreChangePassword(_id, value) {
  try {
    const data = await axios.put(_StoreChangePasswordAPI + "/" + _id, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}



export async function _StoreUpdate(_id, value) {
  try {
    const data = await axios.put(_StoreUpdateAPI + "/" + _id, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}


// export async function _StoreDelete(_id) {
//   try {
//     const data = await axios.delete(_StoreDeleteAPI + "/" + _id);
//     if (data.status < 300) {
//       return { err: false, data: data.data };
//     }
//     return { err: true, message: `err - ${data.status}` };
//   } catch (err) {
//     console.log("err", err);
//     return { err: true, message: err };
//   }
// }
