import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = () => {
  return (
    <div style={{display:"flex",justifyContent: "center",alignItems: "center", width:"100%",minHeight:"50vh"}}>
    <CircularProgress style={{color:"#16C73C"}} />
</div>
  )
}

export default Loading
