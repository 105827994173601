import axios from "axios";
import {BACKEND_ENPOINT} from "../../constants";
// --------------------- //

const _IncomeAndExpendsAPI = BACKEND_ENPOINT + "/income-and-expends";
const _IncomeAndExpendsMonthAPI = BACKEND_ENPOINT + "/income-and-expends-month";
const _IncomeAndExpendAPI = BACKEND_ENPOINT + "/income-and-expend";
const _IncomeAndExpendCreateAPI = BACKEND_ENPOINT + "/create-income-expend";
const _IncomeAndExpendUpdateAPI = BACKEND_ENPOINT + "/update-income-expend";
const _IncomeAndExpendDeleteAPI = BACKEND_ENPOINT + "/delete-income-expend";

export async function _IncomeAndExpends(params = "?") {
  try {
    const data = await axios.get(_IncomeAndExpendsAPI + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}


export async function _IncomeAndExpendMonth(params = "?") {
    try {
      const data = await axios.get(_IncomeAndExpendsMonthAPI + params);
      if (data.status < 300) {
        return { err: false, data: data.data };
      }
      return { err: true, message: `err - ${data.status}` };
    } catch (err) {
      console.log("err", err);
      return { err: true, message: err };
    }
  }

export async function _IncomeAndExpend(_id, params = "?") {
  try {
    const data = await axios.get(_IncomeAndExpendAPI + "/" + _id + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}


export async function _IncomeAndExpendCreate(value) {
  try {
    const data = await axios.post(_IncomeAndExpendCreateAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _IncomeAndExpendUpdate(_id, value) {
  try {
    const data = await axios.put(_IncomeAndExpendUpdateAPI + "/" + _id, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _IncomeAndExpendDelete(_id) {
  try {
    const data = await axios.delete(_IncomeAndExpendDeleteAPI + "/" + _id);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}
