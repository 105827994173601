import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useStore } from "../../providers/useStore";
import AddProductTypePopup from "../../components/popup/AddProductTypePopup";
import EditProductTypePopup from "../../components/popup/EditProductTypePopup";
import DeletePopup from "../../components/popup/DeletePopup";

//function

//components
import { CustomButton, Title, Loading } from "../../components"


import {
  _ProductTypeCreate,
  _ProductTypeUpdate,
  _ProductTypeDelete,
  _ProductTypes,
} from "../../apis/ProductType.service";
import { _StoresReport, _Store } from "../../apis/Stores.service"


//mui
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export default function ProductTypePage() {
  // state
  const { productTypeLoading, setProductTypeLoading } = useStore();
  const { productTypes, setProductTypes } = useStore();
  const [select, setSelect] = useState();
  const { storedData } = useStore();

  const [storeInfo, setStoreInfo] = useState()
  const [popAddProductType, setPopAddProductType] = useState(false);
  const [popEditProductType, setPopEditProductType] = useState(false);
  const [popDeleteProductType, setPopDeleteProductType] = useState(false);


  // functions
  const notifyError = (text) => toast.error(text, { autoClose: 1000, });
  const notifySuccess = (text) => toast.success(text, { autoClose: 1000, });

  const addProductType = async (e) => {
    setPopAddProductType(false);
    const data = await _ProductTypeCreate(e);
    if (!data?.err) {
      notifySuccess("ເພີ່ມສຳເລັດ")
      getData(storedData);
    }
    else notifyError("ເພີ່ມບໍ່ສຳເລັດ")
    getData(storedData);
  };
  const updateProduct = async (e) => {
    setPopEditProductType(false);
    const data = await _ProductTypeUpdate(e?._id, e);
    if (!data?.err) {
      notifySuccess("ແກ້ໄຂສຳເລັດ")
      getData(storedData);
    }
    else notifyError("ແກ້ໄຂບໍ່ສຳເລັດ")
    getData(storedData);
  };
  const deleteProductType = async () => {
    setPopDeleteProductType(false);
    const data = await _ProductTypeDelete(select?._id);
    if (!data?.err) {
      notifySuccess("ລຶບລາຍການສຳເລັດ")
      getData(storedData);
    }
    else notifyError("ບໍ່ສາມາດລຶບໄດ້")
    getData(storedData);

  };

  const getData = async () => {
    setProductTypeLoading(true);
    let params = "?storeId="+storedData?.storeId;

    const data = await _ProductTypes(params);
    if (!data?.err) {
      setProductTypes(data?.data);
    }
    setProductTypeLoading(false);
  };
  useEffect(() => {
   if(storedData)
    getData(storedData);
    getStoreInfo(storedData)
  }, [storedData]);

  const getStoreInfo = async (storedData) => {
    const param = storedData?.storeId
    const responseStore = await _Store(param)
    if (responseStore?.data !== null) {
        setStoreInfo(responseStore?.data)
    }
}
  return (
    <>
     <header>
        <title>Unitech-Store-{storeInfo?.name}-ເພີ່ມປະເພດສິນຄ້າ</title>
      </header>

      <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 20 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>

          <Title text={"ຈັດການປະເພດສິນຄ້າ"} />
          <CustomButton width={130} hidden={28} title="+ ເພີ່ມ" onClick={() => setPopAddProductType(true)} />
        </div>

        {productTypeLoading ? (
          <>
            <Loading />
          </>
        ) : (

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">ລຳດັບ</TableCell>
                  <TableCell align="center">ເນື້ອໃນ</TableCell>
                  {/* <TableCell align="center">ວັນທີສ້າງ</TableCell> */}
                  <TableCell align="center">ຈັດການ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productTypes?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">
                      {row.name}
                    </TableCell>
                    {/* <TableCell align="center">{formatDate(row.createdAt)}</TableCell> */}
                    <TableCell align="center">

                      <Box style={{ display: "flex", gap: 10, justifyContent: "center" }}>

                        <EditIcon onClick={() => {
                          setSelect(row);
                          setPopEditProductType(true);
                        }}
                          style={{ color: "#27B43E" }}
                        />
                        {" "}
                        <DeleteIcon onClick={() => {
                          setSelect(row);
                          setPopDeleteProductType(true);
                        }}
                          style={{ color: "#FF0000" }}
                        />
                      </Box>

                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

      </div>

      {/* popup */}
      <AddProductTypePopup
        open={popAddProductType}
        onClose={() => setPopAddProductType(false)}
        onSubmit={addProductType}
      />
      <EditProductTypePopup
        value={select}
        open={popEditProductType}
        onClose={() => setPopEditProductType(false)}
        onSubmit={updateProduct}
      />
      <DeletePopup
        text={select?.name}
        open={popDeleteProductType}
        onClose={() => setPopDeleteProductType(false)}
        onSubmit={deleteProductType}
      />
      <ToastContainer />

    </>
  );
}
