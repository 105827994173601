import React,{useState} from 'react'
import { useNavigate } from "react-router-dom";

//api
import {_StoreChangePassword} from "../../../apis/Stores.service"
import { useStore } from "../../../providers/useStore";

//component
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//mui
import { FormGroup, FormLabel, TextField, Box, Button, Modal, Backdrop } from "@mui/material";


function ChangePassword({ showFormChangePassword, handleCloseFormChangePassword }) {
    const navigate = useNavigate();
    const { storedData } = useStore();
    //constant
    const [preData, setPreData] = useState({})
    const [errData, setErrData] = useState({})

    /**
     * function
     */
    const notifyError = (text) => toast.error(text, { autoClose: 500, });
    const notifySuccess = (text) => toast.success(text, { autoClose: 500, });
    
    const onSavePassword = async () => {
        try{
            if(!preData?.newPassword && !preData?.confirmPassword){
                setErrData({...errData, errNewPassword: true, errConfirmPassword: true})
                return
            }
            if(!preData?.newPassword) {
                setErrData({...errData, errNewPassword: true})
                return
            }
            if(!preData?.confirmPassword) {
                setErrData({...errData, errConfirmPassword: true})
                return
            }
            if(preData?.confirmPassword !== preData?.newPassword){
                setErrData({...errData, errNotMatch: true})
                return
            }
            setErrData({...errData, errNotMatch: false})
            let _data ={
                password: preData?.newPassword
            }
            const result = await _StoreChangePassword(storedData?.storeId, _data);
            if (result?.data !== null) {
                await handleCloseFormChangePassword()
                await notifySuccess('ປ່ຽນລະຫັດຜ່ານສຳເລັດ')
                await setTimeout(() => {
                     localStorage.clear("token")
                     localStorage.clear("store")
                     navigate('/login')
                }, 1000);
            }
        }
        catch(err){
            notifyError("ບໍ່ສາມາດປ່ຽນລະຫັດຜ່ານໄດ້")
        }
    }

    return (
        <Modal
            open={showFormChangePassword}
            onClose={handleCloseFormChangePassword}
            sx={{ overflowY: "auto" }}
        >
            <Box
                sx={{
                    borderRadius: "8px",
                    boxShadow: 1,
                    p: 2,
                    bgcolor: "background.paper",
                    transform: "translate(-50%, -50%)",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "100%",
                    maxWidth: 500,
                }}
            >
                <div style={{ fontSize: 18, fontWeight: "bold", marginBottom: 20 }}>
                    ປ່ຽນລະຫັດຜ່ານ
                </div>
                <hr style={{ marginBottom: 20 }} />
                <FormGroup>
                    <FormLabel>ລະຫັດຜ່ານໃໝ່</FormLabel>
                    <TextField
                        placeholder="ລະຫັດຜ່ານໃໝ່"
                        name="name"
                        onChange={(e)=> {setPreData({...preData, newPassword: e.target.value});setErrData({...errData, errNewPassword: false}) }}
                        size="small"
                    />
                    <p style={{color:"red"}}>{errData?.errNewPassword ? "ກະລຸນາປ້ອນລະຫັດຜ່ານໃໝ່" : ""}</p>
                </FormGroup>

                <FormGroup style={{ marginTop: 10 }}>
                    <FormLabel>ຢືນຢັນລະຫັດຜ່ານໃໝ່</FormLabel>
                    <TextField
                        placeholder="ຢືນຢັນລະຫັດຜ່ານໃໝ່"
                        name="name"
                        onChange={(e)=> {setPreData({...preData, confirmPassword: e.target.value});setErrData({...errData, errConfirmPassword: false})}}
                        size="small"
                    />
                    <p style={{color:"red"}}>{errData?.errConfirmPassword ? "ກະລຸນາປ້ອນລະຫັດຜ່ານຢືນຢັນ" : ""}</p>
                </FormGroup>

                <p style={{color:"red"}}>{errData?.errNotMatch ? "ລະຫັດຜ່ານບໍ່ກົງກັນ" : ""}</p>


                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                    <Button
                        type="button"
                        onClick={handleCloseFormChangePassword}
                        sx={{ boxShadow: 0, marginTop: 3 }}
                        size="large"
                    >
                        ຍົກເລີກ
                    </Button>
                    &nbsp;
                    <Button
                        type="button"
                        onClick={()=> onSavePassword()}
                        variant="contained"
                        sx={{ boxShadow: 0, marginTop: 3 }}
                        size="large"
                    >
                        ບັນທຶກ
                    </Button>
                </div>
            </Box>
        </Modal>
    )
}

export default ChangePassword