import React from 'react'

//constant
import {convertTypeCurrency} from "../../../../../hooks/helpers"


//mui
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function MobileView({ accountList , setAccountInfo, setConfirmDelete,setShowFormEdit}) {
    return (
        <div className="account-mobile-view">
            {accountList.map((item, index) =>
                <div className='card-account'>
                    <div className='header'>
                        <h3>{item?.accountName ?? "-"}</h3>
                        <p>{convertTypeCurrency(item?.accountCurrency ?? "-")}</p>
                    </div>
                    <div className='body'>
                        <p>{item?.accountNumber ?? "-"}</p>
                    </div>
                    <div className='footer'>
                        <h3>ທ/ຄ: {item?.bankName ?? "-"}</h3>
                        <div className='manage'>
                            <EditIcon
                                onClick={() => {
                                          setAccountInfo(item);
                                          setShowFormEdit(true);
                                        }}
                                style={{ color: "#27B43E",cursor:'pointer' }}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <DeleteIcon
                                onClick={() => {
                                    setAccountInfo(item); setConfirmDelete(true)
                                }}
                                style={{ color: "#FF0000",cursor:'pointer' }}
                            />


                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MobileView