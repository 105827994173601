import React from 'react'
import { useNavigate } from "react-router-dom";

//constant
import Routes from "../../../constants/route"


//mui
import { Grid } from '@mui/material';

function Service() {
    const navigate = useNavigate();


    return (
        <div className='service'>
            <Grid container spacing={1} style={{ padding: 20 }} >

                <Grid item xs={12} sm={3} md={2} lg={2} className='card' onClick={()=> navigate(Routes.ACCOUNT_DESHBOARD)}>
                    <h4>ລາຍຮັບ-ລາຍຈ່າຍ</h4>
                    <center>
                     <img src='/assets/images/service/finance.png' />
                    </center>
                </Grid>

                <Grid item xs={12} sm={3} md={2} lg={2} className='card'>
                    <h4>ສະຕ໋ອກສິນຄ້າ</h4>
                    <center>
                     <img src='/assets/images/service/packages.png' />
                    </center>
                </Grid>

            </Grid>
        </div>
    )
}

export default Service