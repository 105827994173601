import React from 'react'
import { useNavigate, useParams } from "react-router-dom";
import Pagination from '@mui/material/Pagination';

function PaginationHelper() {
  const navigate = useNavigate();
  const params = useParams();
  const [page, setPage] = React.useState(1);
  const _limit = parseInt(params?.limit);
  const _skip = parseInt(params?.skip);

  const Pagination_helper = (total, rout, filtter) => {
    const handleChange = (event,value) => {
      setPage(value);
      let currentPage = value;
      navigate(rout + "/limit/" + _limit + "/skip/" + currentPage, filtter);
    }

    return (
      <Pagination count={Math.ceil(total / _limit)} page={page} boundaryCount={2} onChange={handleChange} />
    )
  }


  return {
    _limit,
    _skip,
    Pagination_helper,
  }
}

export default PaginationHelper