import React from 'react'
import {MAIN_COLOR,COLOR_TEXT_TITLE} from '../constants'

const Title = ({text}) => {
  return (
    <div style={{fontSize: 16, color: COLOR_TEXT_TITLE,fontWeight:'700'}}>
      {text}
    </div>
  )
}

export default Title
