import React from "react";
import { FormGroup, FormLabel, TextField, Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Formik } from "formik";
import { useStore } from "../../providers/useStore";

export default function ColorForm({ value, onSubmit }) {
  const theme = useTheme();
  return (
    <Formik
      initialValues={{
        ...value,
        name: value?.name || "",
      }}
      validate={(values) => {
        const errors = {};

        if(!values.name){
          errors.name="ກະລຸນາຕື່ມຊື່ສີ"
        }


        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        (async () => {
          await onSubmit(values);
          setSubmitting(false);
        })();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <FormGroup>
              <FormLabel>ຊື່ສີ</FormLabel>
              <TextField
                error={errors.name}
                placeholder="ຊື່ສີ"
                name="name"
                value={values?.name}
                onChange={handleChange}
                size="small"
              />
            </FormGroup>
            <Button
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              sx={{ boxShadow: 0 }}
              size="large"
            >
              ບັນທຶກ
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
