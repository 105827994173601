import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";


//component
import { TitleForm } from "../../../components"
import UploadPhoto from "../../../components/UploadProfile";


//apis
import { _Store, _StoreUpdate } from "../../../apis/Stores.service"


//MUI
import {
    FormGroup,
    FormLabel,
    TextField,
    Button,
    Link,
    Breadcrumbs,
    Typography,
} from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ProfileEdit() {
    //constant
    const navigate = useNavigate();
    const param = useParams()
    const storeId = param.storeId

    const {
        namePhoto,
        buttonUploadAndShowPhoto,
        setNamePhoto
    } = UploadPhoto();

    //state
    const [storeInfo, setStoreInfo] = useState()
    //useEffect
    useEffect(() => {
        getStoreInfo(storeId)
    }, [storeId])

    useEffect(() => {
        if (storeInfo) {
            setNamePhoto(storeInfo?.image)
        }
    }, [storeInfo])

    //function
    const notifyError = (text) => toast.error(text, { autoClose: 500, });
    const notifySuccess = (text) => toast.success(text, { autoClose: 500, });

    const getStoreInfo = async (storeId) => {
        const param = storeId
        const responseStore = await _Store(param)
        if (responseStore?.data !== null) {
            setStoreInfo(responseStore?.data)
        }
    }

    const updateProfile = async (_data, e) => {
        const data = await _StoreUpdate(e, _data);
        if (!data?.err) {
            await notifySuccess('ແກ້ໄຂຂໍ້ມູນສ່ວນຕົວສຳເລັດ')
            setTimeout(() => {
                navigate('/profile')
            }, 1000);
        }
        else {
            notifyError("ບໍ່ສາມາດແກ້ໄຂຂໍ້ມູນສ່ວນຕົວໄດ້")
        }
    }

    //validate 
    const val = () => {
        return <span style={{ color: "red" }}>*</span>
    }
    return (
        <>
            <header>
                <title>Unitech-Store-{storeInfo?.name}-ແກ້ໄຂໂປຣຟາຍ</title>
            </header>
            <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 20 }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" onClick={() => navigate('/profile')} style={{ cursor: "pointer" }}>
                            ຂໍ້ມູນສ່ວນຕົວ
                        </Link>
                        <Typography color="text.primary">ແກ້ໄຂຂໍ້ສ່ວນຕົວ</Typography>
                    </Breadcrumbs>
                </div>

                <TitleForm text={"ແກ້ໄຂຂໍ້ສ່ວນຕົວ"} />

                {storeInfo && (
                    <Formik
                        initialValues={{
                            name: storeInfo?.name ?? "",
                            phone: storeInfo?.contacts?.phone ?? "",
                            whatsapp: storeInfo?.contacts?.whatsapp ?? "",
                            fb: storeInfo?.contacts?.fb ?? "",
                            page_fb: storeInfo?.contacts?.page_fb ?? "",
                            wechat: storeInfo?.contacts?.wechat ?? "",
                            line: storeInfo?.contacts?.line ?? "",
                            ticktok: storeInfo?.contacts?.ticktok ?? "",
                            address: storeInfo?.address ?? "",
                        }}
                        validate={(values) => {
                            const errors = {};

                            if (!values.name) errors.name = "ກະລຸນາຕື່ມຊື່ຮ້ານ"
                            if (!values.phone) errors.name = "ກະລຸນາຕື່ມເບີໂທລະສັບ"
                            if (!values.whatsapp) errors.type = "ກະລຸນາຕື່ມເບີວອດແອັບ"

                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            (async () => {

                                let _data = {
                                    ...values,
                                    image: namePhoto,
                                    contacts: {
                                        phone: values.phone,
                                        whatsapp: values.whatsapp,
                                        fb: values.fb,
                                        page_fb: values.page_fb,
                                        wechat: values.wechat,
                                        ticktok: values.ticktok,
                                        line: values.line,
                                    }
                                }

                                delete _data.phone
                                delete _data.whatsapp
                                delete _data.fb
                                delete _data.page_fb
                                delete _data.wechat
                                delete _data.ticktok
                                delete _data.line

                                await updateProfile(_data, storeInfo?._id);
                                setSubmitting(false);
                            })();
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 10,
                                    }}
                                >
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        {buttonUploadAndShowPhoto()}
                                    </div>

                                    <FormGroup>
                                        <FormLabel>ປ້ອນຊື່ຮ້ານ {val()}</FormLabel>
                                        <TextField
                                            error={!!errors.name}
                                            placeholder="ປ້ອນຊື່ຮ້ານ"
                                            name="name"
                                            value={values?.name}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <FormLabel>ເບີໂທ {val()}</FormLabel>
                                        <TextField
                                            error={!!errors.phone}
                                            placeholder="ເບີໂທ"
                                            name="phone"
                                            value={values?.phone}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <FormLabel>ເບີວອດແອັບ {val()}</FormLabel>
                                        <TextField
                                            error={!!errors.whatsapp}
                                            placeholder="ຕົວຢ່າງ: 56891992"
                                            name="whatsapp"
                                            value={values?.whatsapp}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <FormLabel>ລິ້ງເຟສບຸກ (ຖ້າມີ)</FormLabel>
                                        <TextField
                                            placeholder="ລິ້ງເຟສບຸກ"
                                            name="fb"
                                            value={values?.fb}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <FormLabel>ລິ້ງເພສ (ຖ້າມີ)</FormLabel>
                                        <TextField
                                            placeholder="ລິ້ງເຟສບຸກ"
                                            name="page_fb"
                                            value={values?.page_fb}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <FormLabel>ຊື່ Ticktok (ຖ້າມີ)</FormLabel>
                                        <TextField
                                            placeholder="ຊຶ່ Ticktok"
                                            name="ticktok"
                                            value={values?.ticktok}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <FormLabel>ຊື່ Wechat (ຖ້າມີ)</FormLabel>
                                        <TextField
                                            placeholder="ຊຶ່ wechat"
                                            name="wechat"
                                            value={values?.wechat}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <FormLabel>ຊື່ Line (ຖ້າມີ)</FormLabel>
                                        <TextField
                                            placeholder="ຊຶ່ Line"
                                            name="line"
                                            value={values?.line}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <FormLabel>ທີ່ຢູ່ຮ້ານ (ຖ້າມີ)</FormLabel>
                                        <TextField
                                            placeholder="ທີ່ຢູ່ຮ້ານ..."
                                            name="address"
                                            value={values?.address}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>


                                    <Button
                                        type="submit"
                                        onClick={handleSubmit}
                                        variant="contained"
                                        sx={{ boxShadow: 0, fontWeight: "700" }}
                                        size="large"
                                    >
                                        ບັກທຶກ
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                )}

                <ToastContainer />

            </div>
        </>

    )
}

export default ProfileEdit