import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";


//constant
import { URL_PHOTO_AW3,PAGE_GINATION } from "../../constants"
import { formatDate, formatCurrency } from "../../hooks/helpers"
import { useStore } from "../../providers/useStore";

//api
import { _StoresReport, _Store } from "../../apis/Stores.service"


// MUI
import { styled } from '@mui/material/styles';

import {
    Box,
    Breadcrumbs,
    Link,
    Typography,
    Grid,
    Paper
} from '@mui/material';

const Order = styled(Paper)(({theme }) => ({
    marginTop: 10,
    width: '100%',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10
}));


function OrderHistoryDetail() {
    const navigate = useNavigate();

    const location = useLocation();
    const { storedData } = useStore();


    //state
    const [storeInfo, setStoreInfo] = useState()
    const [detailOrder, setDetailOrder] = useState()
    const [totalPrice, setTotalPrice]= useState(0)

    useEffect(() => {
        getStoreInfo(storedData)
    }, [storedData])

    useEffect(() => {
        if (location.state) setDetailOrder(location.state)
    }, [location])

    useEffect(() => {

        if(detailOrder){
            let sum = 0
            for(var i=0; i < detailOrder?.order.length; i++){
                sum += detailOrder?.order[i].totalPrice
            }
            setTotalPrice(sum)
        }

    },[detailOrder])

    const getStoreInfo = async (storedData) => {
        const param = storedData?.storeId
        const responseStore = await _Store(param)
        if (responseStore?.data !== null) {
            setStoreInfo(responseStore?.data)
        }
    }


    return (
        <div>
             <header>
                <title>Unitech-Store-{storeInfo?.name}-ລາຍລະອຽດສັ່ງຊື້</title>
            </header>
            <Box container spacing={2} style={{ padding: 20 }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link style={{cursor: "pointer"}} underline="hover" color="inherit" onClick={() => navigate('/order-history'+PAGE_GINATION)}>
                        ປະຫວັດການສັ່ງຊື້
                    </Link>
                    <Typography color="text.primary">ລາຍລະອຽດສັ່ງຊື້</Typography>
                </Breadcrumbs>

                <Grid xs={12} sm={12} md={12} lg={12} style={{lineHeight:2, marginLeft:10,marginTop:20}}>
                    <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                        <font style={{fontWeight: "700"}}>ຊື່:</font> {detailOrder?.fullname}
                    </Typography>
                    <br></br>
                    <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                       <font style={{fontWeight: "700"}}>ເບີໂທ:</font>  {detailOrder?.phone}
                    </Typography>
                    <br></br>
                    <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                        <font style={{fontWeight: "700"}}>ສະຖານທີ່ສົ່ງ:</font> {detailOrder?.address}
                    </Typography>
                    <br></br>
                    <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                       <font style={{fontWeight: "700"}}>ວັນທີສັ່ງ:</font> {formatDate(detailOrder?.createdAt)}
                    </Typography>
                    <br></br>

                    <Typography
                        sx={{ display: 'inline', color: "#00905E", fontSize:25 }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                       <font style={{fontWeight: "700"}}>ລວມເປັນເງິນ:</font> {formatCurrency(totalPrice)} ກີບ
                    </Typography>

                </Grid>
                <Grid xs={12} >
                    <Box sx={{ marginLeft: 1, marginRight: 4 }}>
                        {detailOrder && detailOrder?.order?.length > 0 ? detailOrder?.order?.map((item, index) =>
                            <Order key={index}>
                                <div style={{ display: 'flex', flexDirection: "row" }}>
                                    <LazyLoadImage src={URL_PHOTO_AW3 + "/" + item?.images} style={{ width: 120, height: 120 }} />
                                    <div style={{ paddingLeft: 10 }}>
                                        <p className='h2-1'>{item?.name ?? ""}</p>
                                        <p className='h4'>{item?.price ?? ""} ກີບ</p>
                                        <p className='h4'>ຂະ​ໜາດ: {item?.size ?? ""}</p>
                                        <p className='h4'>ສີ: ​{item?.color ?? ""}</p>
                                        <p className='h4'>ຈຳ​ນວນ: {item?.qty ?? ""} </p>
                                        <p className='h4'>ລາຄາ: {formatCurrency(item?.price) ?? ""} </p>
                                        <p className='h4'>ລວມເງິນ: {formatCurrency(item?.totalPrice) ?? item?.price * item?.qty} </p>
                                    </div>
                                </div>
                            </Order>
                        ) : (
                            <Order>
                                <p style={{ textAlign: "center" }}>ບໍ່ມີລາຍການສັ່ງຊື້</p>
                            </Order>
                        )}
                    </Box>
                </Grid>

            </Box>
        </div>
    )
}

export default OrderHistoryDetail