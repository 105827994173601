import React from 'react'
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

//api
import { _AccountUpdate } from "../../../../../apis"
import { useStore } from "../../../../../providers/useStore";

//component
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//mui
import { FormGroup, FormLabel, TextField, Box, Button, Modal, Backdrop, Select, MenuItem } from "@mui/material";


function FormEditAccount({ showFormEdit, clearInfo, fetchData, accountInfo }) {
    const { storedData } = useStore();

    /**
     * function
     */
    const notifyError = (text) => toast.error(text, { autoClose: 500, });
    const notifySuccess = (text) => toast.success(text, { autoClose: 500, });
    const notifyWarning = (text) => toast.warning(text, { autoClose: 500, });

    const onSaveUpdata = async (values) => {
        let _data = {
            ...values,
            storeId: storedData?.storeId
        }

        const result = await _AccountUpdate(accountInfo?._id, _data)
        if (result?.err) {
            notifyError(`ບໍ່ສາມາດແກ້ໄຂໄດ້`)
            return;
        }
        else {
            await clearInfo()
            await fetchData()
            await notifySuccess('ແກ້ໄຂສຳເລັດ')
        }
    }

    //validate 
    const val = () => {
        return <span style={{ color: "red" }}>*</span>
    }
    return (
        <>
            {accountInfo && (
                <Formik
                    initialValues={{
                        accountName: accountInfo?.accountName ?? "",
                        accountCurrency: accountInfo?.accountCurrency ?? "",
                        bankName: accountInfo?.bankName ?? "",
                        accountNumber: accountInfo?.accountNumber ?? "",
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.accountName) errors.accountName = "ກະລຸນາປ້ອນຊື່ທະນາຄານ"
                        if (!values.accountCurrency) errors.accountCurrency = "ກະລຸນາຊື່ບັນຊີ"
                        if (!values.bankName) errors.bankName = "ກະລຸນາເລືອກສະກຸນເງິນ"

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        (async () => {
                            if(accountInfo?.accountName === values.accountName) delete values.accountName;
                            if(accountInfo?.accountCurrency === values.accountCurrency) delete values.accountCurrency;
                            if(accountInfo?.bankName === values.bankName) delete values.bankName;

                            await onSaveUpdata(values);
                            setSubmitting(false);
                            resetForm();
                        })();
                    }}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal
                                open={showFormEdit}
                                onClose={() => clearInfo()}
                                sx={{ overflowY: "auto" }}
                            >
                                <Box
                                    sx={{
                                        borderRadius: "8px",
                                        boxShadow: 1,
                                        p: 2,
                                        bgcolor: "background.paper",
                                        transform: "translate(-50%, -50%)",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        width: "100%",
                                        maxWidth: 500,
                                    }}
                                >
                                    <div style={{ fontSize: 18, fontWeight: "bold", marginBottom: 20 }}>
                                        ເພີ່ມບັນຊີທະນາຄານ
                                    </div>
                                    <hr style={{ marginBottom: 20 }} />
                                    <FormGroup >
                                        <FormLabel>ຊື່ທະນາຄານ {val()}</FormLabel>
                                        <TextField
                                            type='text'
                                            placeholder="ປ້ອນຊື່ທະນາຄານ"
                                            size="small"
                                            value={values?.bankName}
                                            name="bankName"
                                            error={!!errors.bankName}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>

                                    <FormGroup style={{ marginTop: 10 }}>
                                        <FormLabel>ຊື່ບັນຊີ {val()}</FormLabel>
                                        <TextField
                                            placeholder="ປ້ອນຊື່ບັນຊີ"
                                            size="small"
                                            type='text'
                                            value={values?.accountName}
                                            name="accountName"
                                            error={!!errors.accountName}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>

                                    <FormGroup style={{ marginTop: 10 }}>
                                        <FormLabel>ເລກບັນຊີ</FormLabel>
                                        <TextField
                                            placeholder="ປ້ອນຊື່ເລກບັນຊີ"
                                            size="small"
                                            type='text'
                                            value={values.accountNumber}
                                            name="accountNumber"
                                            error={!!errors.accountNumber}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>


                                    <FormGroup style={{ marginTop: 10 }}>
                                        <FormLabel>
                                            ສະກຸນເງິນ {val()}
                                        </FormLabel>
                                        <Select
                                            value={values?.accountCurrency}
                                            name="accountCurrency"
                                            error={!!errors.accountCurrency}
                                            onChange={handleChange}
                                            size="small"
                                        >
                                            <MenuItem value={"KIP"}>ກີບ</MenuItem>
                                            <MenuItem value={"BATH"}>ບາດ</MenuItem>
                                            <MenuItem value={"USD"}>ໂດລາ</MenuItem>
                                            <MenuItem value={"CNY"}>ຢວນ</MenuItem>
                                        </Select>

                                    </FormGroup>



                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                                        <Button
                                            type="button"
                                            onClick={() => clearInfo()}
                                            sx={{ boxShadow: 0, marginTop: 3 }}
                                            size="large"
                                        >
                                            ຍົກເລີກ
                                        </Button>
                                        &nbsp;
                                        <Button
                                            onClick={handleSubmit}
                                            disabled={isSubmitting}
                                            variant="contained"
                                            sx={{ boxShadow: 0, marginTop: 3 }}
                                            size="large"
                                        >
                                            ບັນທຶກ
                                        </Button>
                                    </div>
                                </Box>
                            </Modal>
                        </form>
                    )}
                </Formik>
            )}
        </>
    )
}

export default FormEditAccount