import axios from "axios";
import {BACKEND_ENPOINT} from "../constants";
// --------------------- //

const _AdssAPI = BACKEND_ENPOINT + "/adss";
const _AdsAPI = BACKEND_ENPOINT + "/ads";
const _AdsCreateAPI = BACKEND_ENPOINT + "/ads";
const _AdsUpdateAPI = BACKEND_ENPOINT + "/ads";
const _AdsDeleteAPI = BACKEND_ENPOINT + "/ads";

export async function _Adss(params = "?") {
  try {
    const data = await axios.get(_AdssAPI + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _Ads(_id, params) {
  try {
    const data = await axios.get(_AdsAPI + "/" + _id + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _AdsCreate(value) {
  try {
    const data = await axios.post(_AdsCreateAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _AdsUpdate(_id, value) {
  try {
    const data = await axios.put(_AdsUpdateAPI + "/" + _id, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _AdsDelete(_id) {
  try {
    const data = await axios.delete(_AdsDeleteAPI + "/" + _id);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}
