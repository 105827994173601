import React from "react";
import { styled } from "@mui/material/styles";

export default function Table({ children }) {
  return <TableComponent>{children}</TableComponent>;
}

const TableComponent = styled("table")(({ theme }) => ({
  border: 1,
  borderCollapse: "collapse",
  width: "100%",    
  td: {
    padding: 10,
    border: "1px solid #000",
  },
  th: {
    padding: 10,
    border: "1px solid #000",
  },
}));
