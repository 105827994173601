import React, { useState, useEffect } from "react";
import { Box, Grid, Divider } from "@mui/material";
import { useStore } from "../../providers/useStore";
import DeletePopup from "../../components/popup/DeletePopup";
import { useNavigate } from "react-router-dom";
import { URL_PHOTO_AW3 } from "../../constants";

//contant
import Routes from "../../constants/route"


//components
import { CustomButton, Title, Loading } from "../../components"
import Pagination from "../../hooks/helpers/PaginationHelper";

//function
import { formatDate, formatCurrency, converStatus, convertTypeCurrency } from "../../hooks/helpers"

//api
import {
  _ProductDelete,
  _Products,
} from "../../apis/Products.service";
import { _ProductTypes } from "../../apis/ProductType.service";
import { _Store } from "../../apis/Stores.service"

//mui
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function ProductsPage() {
  const navigate = useNavigate();
  const { _limit, _skip, Pagination_helper } = Pagination();

  // state
  const [storeInfo, setStoreInfo] = useState()

  const { setProductTypeLoading } = useStore();
  const { productLoading, setProductLoading } = useStore();
  const [products, setProducts] = useState([]);
  const { storedData } = useStore();
  const [select, setSelect] = useState();
  const [popDeleteProduct, setPopDeleteProduct] = useState(false);
  const { productTypes, setProductTypes } = useStore();
  const [expanded, setExpanded] = React.useState(false);

  const [productTotal, setProductTotal] = useState(0);

  //fitler
  const [isActive, setIsActive] = useState("ALL");
  const [productTypeId, setProductTypeId] = useState("ALL");
  const handleSelectType = (newValue) => {
    setProductTypeId(newValue);
    setIsActive(newValue);
  };

  // functions
  const deleteProduct = async () => {
    setPopDeleteProduct(false);
    await _ProductDelete(select?._id);
    getData(productTypeId);
  };

  useEffect(() => {
    let storeLocal = window.localStorage.getItem('store');
    let storeIdLocal = JSON.parse(storeLocal)
    if (storeIdLocal === null) return;
    getStoreInfo(storeIdLocal?.storeId)

  }, [])


  useEffect(() => {
    getData(productTypeId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productTypeId, _limit, _skip])

  const getStoreInfo = async (storedData) => {
    const param = storedData
    const responseStore = await _Store(param)
    if (responseStore?.data !== null) {
      setStoreInfo(responseStore?.data)
    }
  }

  const getData = async (productTypeId) => {
    setProductLoading(true);
    setProductTypeLoading(true);

    let params = "?storeId=" + storedData?.storeId;

    if (productTypeId === "ALL") {
      params = "?storeId=" + storedData?.storeId;
    }
    else {
      params = "?storeId=" + storedData?.storeId + "&type=" + productTypeId;

    }


    let paramsType = "?storeId=" + storedData?.storeId;

    const dataProductype = await _ProductTypes(paramsType);
    if (!dataProductype?.err) {
      setProductTypes(dataProductype?.data);
    }

    const data = await _Products(params);
    if (!data?.err) {
      setProducts(data?.data?.data);
      setProductTotal(data?.data?.total)
    }
    setProductTypeLoading(false);
    setProductLoading(false);
  };



  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <header>
        <title>Unitech-Store-{storeInfo?.name}-ລາຍການສິນຄ້າ</title>
      </header>
      <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 20 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Title text={"ຈັດການສິນຄ້າ"} />
          <CustomButton width={130} hidden={28} title="+ ເພີ່ມ" onClick={() => navigate("/product-add")} />
        </div>

        <Box>
          <div
            style={{
              display: "flex",
              overflowY: "scroll",
              justifyContent: "start",
              paddingBottom: "10px",
              paddingTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.06)",
                  height: "40px",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "80px",
                  borderRadius: "10px",
                  marginLeft: "10px",
                  fontSize: "16px",
                  background: isActive === "ALL" ? 'linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)' : '',
                  color: isActive === "ALL" ? "#ffffff" : "#6D6D6D",
                  fontWeight: isActive === "ALL" ? "bold" : "",
                }}
                onClick={() => handleSelectType("ALL")}
              >
                <span>
                  ທັງໝົດ
                </span>
              </div>
            </div>
            <span style={{ width: "15px" }}></span>

            {productTypes &&
              productTypes.map((productType, index) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    key={"type" + index}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.06)",
                        height: "40px",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: "81px",
                        cursor: "pointer",
                        minWidth: "80px",
                        borderRadius: "10px",
                        marginLeft: "10px",
                        fontSize: "16px",
                        background: isActive === productType?._id ? 'linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)' : '',
                        color: isActive === productType?._id ? "#ffffff" : "#6D6D6D",
                        fontWeight: isActive === productType?._id ? "bold" : "",
                      }}
                      onClick={() => handleSelectType(productType?._id)}

                    >
                      <span>
                        {productType?.name}
                      </span>
                    </div>
                  </div>
                  <span style={{ width: "20px" }}></span>
                </>
              ))}
          </div>
        </Box>


        {
          productLoading ? (
            <>
              <Loading />
            </>
          ) : products?.map((e, i) => (
            <Accordion key={i} expanded={expanded === 'panel' + i} onChange={handleChange('panel' + i)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <div>
                    <img src={URL_PHOTO_AW3 + '/' + e?.images[0]} style={{ width: 70, height: 70, borderRadius: "50%" }} alt="img" />
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <p>{e?.name}</p>
                    <font style={{ color: "#6D6D6D", fontSize: 11 }}> ວັນທີສ້າງ: {formatDate(e?.createdAt)}</font>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Box style={{ display: "flex", gap: 10, justifyContent: "end" }}>
                  <span onClick={() => navigate('/product-edit/' + e?._id)} style={{ textAlign: "center", borderRadius: "15px", backgroundColor: "#27B43E", color: "#FFFFFF", fontWeight: "500", padding: 5, width: 50, cursor: "pointer" }}>
                    <EditIcon />
                  </span>
                  {" "}
                  <span onClick={() => {
                    setSelect(e);
                    setPopDeleteProduct(true);
                  }} style={{ textAlign: "center", borderRadius: "15px", backgroundColor: "#FF0000", color: "#FFFFFF", fontWeight: "500", padding: 5, width: 50, cursor: "pointer" }}>
                    <DeleteIcon />
                  </span>
                </Box>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                <Grid container spacing={2}>
                  <Grid item xs={5} style={{ fontWeight: "700" }}>ຊື່ປະເພດສິນຄ້າ</Grid>
                  <Grid item xs={7}>{e?.type?.name}</Grid>
                </Grid>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Grid container spacing={2}>
                  <Grid item xs={5} style={{ fontWeight: "700" }}>ຊື່ສິນຄ້າ</Grid>
                  <Grid item xs={7}>{e?.name}</Grid>
                </Grid>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                <Grid container spacing={2}>
                  <Grid item xs={5} style={{ fontWeight: "700" }}>ລາຄາຕົ້ນທຶນ</Grid>
                  <Grid item xs={7}>{formatCurrency(e?.constPrice)} ກີບ</Grid>
                </Grid>

                <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                <Grid container spacing={2}>
                  <Grid item xs={5} style={{ fontWeight: "700" }}>ລາຄາຂາຍ</Grid>
                  <Grid item xs={7}>{formatCurrency(e?.price)} ກີບ</Grid>
                </Grid>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                <Grid container spacing={2}>
                  <Grid item xs={5} style={{ fontWeight: "700" }}>ລາຄາສ່ວນຫລຸດ</Grid>
                  <Grid item xs={7}>{formatCurrency(e?.priceDiscount)} ກີບ</Grid>
                </Grid>

                <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                <Grid container spacing={2}>
                  <Grid item xs={5} style={{ fontWeight: "700" }}>ສະກຸນເງິນ</Grid>
                  <Grid item xs={7}>{convertTypeCurrency(e?.currency)}</Grid>
                </Grid>

                <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                <Grid container spacing={2}>
                  <Grid item xs={5} style={{ fontWeight: "700" }}>ສະຖານະ</Grid>
                  <Grid item xs={7}>{converStatus(e?.status)}</Grid>
                </Grid>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                <Grid container spacing={2}>
                  <Grid item xs={5} style={{ fontWeight: "700" }}>ຈຳນວນສິນຄ້າ</Grid>
                  <Grid item xs={7} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    {formatCurrency(e?.stock)}
                    <button onClick={() => navigate(Routes.PRODUCT_IMPORT_STOCK + "/" + e?._id)} style={{ textAlign: "center", borderRadius: "15px", backgroundColor: "#27B43E", color: "#FFFFFF", fontWeight: "500", cursor: "pointer",border:0 }}>
                      <AddCircleOutlineIcon />
                    </button>
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                <Grid container spacing={2}>
                  <Grid item xs={5} style={{ fontWeight: "700" }}>ລາຍລະອຽດ</Grid>
                  <Grid item xs={7}>{e?.detail}</Grid>
                </Grid>

                <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ fontWeight: "700" }}>ຮູບ</Grid>
                  {e?.images?.map((image, im) =>
                    <Grid item xs={6} sm={3} md={1} lg={1} key={im}>
                      <img src={URL_PHOTO_AW3 + '/' + image} style={{ width: "100%", height: 100, }} alt="img" />
                    </Grid>
                  )}
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={5} style={{ fontWeight: "700" }}>ຕົວເລືອກ</Grid>
                  <Grid item xs={7}>
                    {e?.productOption?.map((item, index) =>
                      <p key={index}>
                        {item?.typeName} (
                        {item?.choices?.map((choice, i) =>
                          <span key={"choice" + i}>{choice?.name}, </span>
                        )}
                        )
                      </p>
                    )}
                  </Grid>
                </Grid>

              </AccordionDetails>
            </Accordion>
          ))
        }


        {/* {productTotal <= 0 ? "" : (
          <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
            {Pagination_helper(productTotal, "/product")}
          </div>
        )} */}


      </div >
      {/* popup */}
      <DeletePopup
        text={`ທ່ານຕ້ອງການລຶບ ${select?.name} ແທ້ ຫຼື ບໍ່?`}
        open={popDeleteProduct}
        onClose={() => setPopDeleteProduct(false)
        }
        onSubmit={deleteProduct}
      />
    </>
  );
}
