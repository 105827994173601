export const BACKEND_ENPOINT = "https://unitech-server.onrender.com";
// export const BACKEND_ENPOINT = "http://localhost:8000";
export const URL_PHOTO_AW3 = "https://bucket-unimarket-files.s3.ap-southeast-1.amazonaws.com";
export const PRESIGNED_URL = BACKEND_ENPOINT + "/uploadfile";
export const PRESIGNED_URL_MANY = BACKEND_ENPOINT + "/uploadfile-many";

export const MAIN_COLOR="#00905E"
export  const COLOR_TEXT_TITLE="#6D6D6D"


export const PAGE_GINATION='/limit/50/skip/1';

