import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { NumericFormat } from 'react-number-format';
import moment from "moment";


//api
import { _StockCreate, _Stocks } from "../../../../apis/Stock.service";

import { Modal, FormGroup,MenuItem, FormLabel, TextField, Box, Button, Backdrop,Select } from "@mui/material";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




export default function FormImport({
    showFormAdd,
    setShowFormAdd,
    productId,
    fetchData
}) {


    /**
     * state
     */
    const [storeId, setStorId] = useState()


    /**
     * useEffect
     */

    useEffect(() => {
        let storeLocal = window.localStorage.getItem('store');
        let storeIdLocal = JSON.parse(storeLocal)
        if (storeIdLocal === null) return;
        setStorId(storeIdLocal?.storeId)
    }, [])






    const notifyError = (text) => toast.error(text, { autoClose: 500, });
    const notifyWarning = (text) => toast.warning(text, { autoClose: 500, });
    const notifySuccess = (text) => toast.success(text, { autoClose: 500, });


    //onSave
    const onSave = async (e) => {
        const data = await _StockCreate(e);
        if (!data?.err) {
            await notifySuccess('ເພີ່ມສິນຄ້າສຳເລັດ')
            await fetchData()
            await setShowFormAdd(!showFormAdd)
        }
        else notifyError("ເພີ່ມບໍ່ສຳເລັດ")
    }


    return (
        <Modal
            open={showFormAdd}
            onClose={() => setShowFormAdd(!showFormAdd)}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            sx={{ overflowY: "auto" }}
        >
            <Box
                sx={{
                    borderRadius: "8px",
                    boxShadow: 1,
                    p: 2,
                    bgcolor: "background.paper",
                    transform: "translate(-50%, -50%)",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "100%",
                    maxWidth: 500,
                }}
            >
                <div style={{ fontSize: 18, fontWeight: "bold", marginBottom: 20 }}>
                    ເພີ່ມສະຕ໋ອກ
                </div>
                <hr style={{ marginBottom: 20 }} />

                <Formik
                    initialValues={{
                        qty: 0,
                        price: 0,
                        type: "IMPORT",
                        productId: productId,
                        storeId: storeId,
                        currency: ""
                    }}
                    validate={(values) => {
                        const errors = {};

                        if (!values.qty) {
                            errors.qty = "ກະລຸນາຕື່ມຈຳນວນ"
                        }
                        if (!values.currency) {
                            errors.currency = "ກະລຸນາເລືອກສະກຸນເງິນ"
                        }
                        if (values.qty <= 0) {
                            errors.qty = "ກະລຸນາຕື່ມຈຳນວນໃຫ່ຍກວ່າ ຫຼື ເທົ່າກັບ 1 ຂື້ນໄປ"
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        (async () => {
                            let _price = values.price.toString()
                            let priceProduct = parseInt(_price.replaceAll(',', ''))
                            let _data = {
                                ...values,
                                price: priceProduct ?? 0,
                            }

                            delete values.price

                            await onSave(_data);
                            setSubmitting(false);
                        })();
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 10,
                                }}
                            >
                                <FormGroup>
                                    <FormLabel>ວັນທີນຳເຂົ້າ</FormLabel>
                                    <TextField
                                        name="dateImport"
                                        type="date"
                                        defaultValue={moment().format("YYYY-MM-DD")}
                                        value={values?.dateImport}
                                        onChange={handleChange}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>ຈຳນວນນຳເຂົ້າ</FormLabel>
                                    <TextField
                                        placeholder="ຈຳນວນນຳເຂົ້າ"
                                        name="qty"
                                        type='number'
                                        value={values?.qty}
                                        onChange={handleChange}
                                        size="small"
                                    />
                                    <p style={{ color: "red" }}>{errors.qty}</p>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>ລາຄານຳເຂົ້າ</FormLabel>
                                    <NumericFormat
                                        allowLeadingZeros
                                        thousandSeparator=","
                                        decimalScale={2}
                                        allowNegative={false}
                                        value={0}
                                        type="text"
                                        placeholder="ປ້ອນລາຄານຳເຂົ້າ"
                                        name="price"
                                        customInput={TextField}
                                        onChange={handleChange}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup>
                                        <FormLabel>
                                            ສະກຸນເງິນ 
                                        </FormLabel>
                                        <Select
                                            value={values?.currency}
                                            name="currency"
                                            error={!!errors.currency}
                                            onChange={handleChange}
                                            size="small"
                                        >
                                            <MenuItem value={"KIP"}>ກີບ</MenuItem>
                                            <MenuItem value={"BATH"}>ບາດ</MenuItem>
                                            <MenuItem value={"USD"}>ໂດລາ</MenuItem>
                                            <MenuItem value={"CNY"}>ຢວນ</MenuItem>
                                        </Select>
                                        <p style={{ color: "red" }}>{errors.currency}</p>
                                </FormGroup>

                                <FormGroup>
                                    <FormLabel>ລາຍລະອຽດສິນຄ້າ</FormLabel>
                                    <TextField
                                        name="detail"
                                        value={values?.detail}
                                        onChange={handleChange}
                                        placeholder="ລາຍລະອຽດສິນຄ້າ..."
                                        multiline
                                        InputProps={{ style: { minHeight: 100, alignItems: "start", } }}
                                    />
                                </FormGroup>



                                <Button
                                    type="submit"
                                    onClick={handleSubmit}
                                    variant="contained"
                                    sx={{ boxShadow: 0 }}
                                    size="large"
                                >
                                    ບັນທຶກ
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>




            </Box>
        </Modal>
    );
}
