import { Outlet } from "react-router-dom";

import SettingPage from "../pages/setting/index"

// eslint-disable-next-line
export default {
    path: "/",
    element: <Outlet />,
    children: [
      { 
        path:"setting-page",
        element: <SettingPage />,
      }
    ]
    
  };
  