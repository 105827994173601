import axios from "axios";
import {BACKEND_ENPOINT} from "../constants";

// --------------------- //

const _LoginAPI = BACKEND_ENPOINT + "/login";
const _LogoutAPI = "";
const _SignUpAPI = "";
const _DeleteAccountAPI = "";

export async function _Login({ username, password }) {
  try {
    const data = await axios.post(_LoginAPI, { username, password });
    if (data.status < 300) {
      const _token = data.data.accessToken;
      axios.defaults.headers.common["Authorization"] = `STORE ${_token}`;
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _Logout(value) {
  try {
    const data = await axios.post(_LogoutAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _SignUp(value) {
  try {
    const data = await axios.post(_SignUpAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _DeleteAccount(value) {
  try {
    const data = await axios.delete(_DeleteAccountAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}
