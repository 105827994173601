import axios from "axios";
import {BACKEND_ENPOINT} from "../constants";
// --------------------- //

const _StocksAPI = BACKEND_ENPOINT + "/stocks";
const _StockAPI = BACKEND_ENPOINT + "/stock";
const _StockCreateAPI = BACKEND_ENPOINT + "/create-stock";
const _StockUpdateAPI = BACKEND_ENPOINT + "/update-stock";
const _StockDeleteAPI = BACKEND_ENPOINT + "/delete-stock";

export async function _Stocks(params = "?") {
  try {
    const data = await axios.get(_StocksAPI + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _Stock(_id, params) {
  try {
    const data = await axios.get(_StockAPI + "/" + _id + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _StockCreate(value) {
  try {
    const data = await axios.post(_StockCreateAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _StockUpdate(_id, value) {
  try {
    const data = await axios.put(_StockUpdateAPI + "/" + _id, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _StockDelete(_id) {
  try {
    const data = await axios.delete(_StockDeleteAPI + "/" + _id);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}
