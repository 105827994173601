import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { NumericFormat } from 'react-number-format';

import Routes from "../../../constants/route"
import { Formik } from "formik";
import moment from 'moment';
//constant
import { useStore } from "../../../providers/useStore";

//component
import { CustomButton, Title, Loading } from "../../../components"
import UploadPhoto from "../../../components/UploadProfile"

//api
import { _TypeAccounts, _Accounts,_IncomeAndExpendCreate } from "../../../apis"


//mui
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    FormGroup,
    FormLabel,
    TextField,
    FormControl,
    MenuItem,
    Select,
    Button,
    Link,
    Breadcrumbs,
    Typography,
    Grid
} from "@mui/material";


function FormAddIncomeAndExpend() {
    const navigate = useNavigate();
    const { storedData } = useStore();

    //state
    const [filterType, setFilterType] = useState("EXPEND");
    const [selectCurrency, setSelectCurrency] = useState("KIP")
    const [typeAccountList, setTypeAccountList] = useState([])
    const [accountList, setAccountList] = useState([])

    const {
        namePhoto,
        buttonUploadAndShowPhoto,
        setNamePhoto
    } = UploadPhoto();

    //useEffect
    useEffect(() => {
        getData(filterType)
    }, [filterType])

    useEffect(() => {
        getAccount(selectCurrency)
    }, [selectCurrency])


    //function
    const getData = async (filter) => {
        let params = "?storeId=" + storedData?.storeId;
        if (filter) {
            params = "?storeId=" + storedData?.storeId + "&type=" + filter;
        }
        const dataTypeAccount = await _TypeAccounts(params);
        if (!dataTypeAccount?.err) {
            setTypeAccountList(dataTypeAccount?.data);
        }
    };
    const getAccount = async (filter) => {
        let params = "?storeId=" + storedData?.storeId;
        if (filter) {
            params = "?storeId=" + storedData?.storeId + "&accountCurrency=" + filter;
        }
        const dataTypeAccount = await _Accounts(params);
        if (!dataTypeAccount?.err) {
            setAccountList(dataTypeAccount?.data);
        }
    };

    const notifyError = (text) => toast.error(text, { autoClose: 500, });
    const notifySuccess = (text) => toast.success(text, { autoClose: 500, });
    const notifyWarning = (text) => toast.warning(text, { autoClose: 500, });

    // create list
    const createIncomeAndExpend = async (values) =>{
        let _data = {
            ...values,
            storeId: storedData?.storeId
        }

        const result = await _IncomeAndExpendCreate(_data)
        if (result?.err) {
            if (result?.message?.response?.data?.message === "PLEASE_ENTER_ALL_FIELEDS") {
                notifyWarning(`ກະລຸນາຕື່ມຂໍ້ມູນໃຫ້ຄົບ`)
            }
            notifyError(`ບໍ່ສາມາດເພີ່ມໄດ້`)
            return;
        }
        else {
            await notifySuccess('ບັນທຶກສຳເລັດ')
            await navigate(Routes.ACCOUNT_DESHBOARD)
        }
    }



    //validate 
    const val = () => {
        return <span style={{ color: "red" }}>*</span>
    }
    const _active = {
        backgroundColor: "#4aa673",
        color: '#fff'
    }

    const _noActive = {
        backgroundColor: "#eeeeee",
        color: '#000',
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 10 }}>
                <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 10 }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" onClick={() => navigate(Routes.ACCOUNT_DESHBOARD)} style={{ cursor: "pointer" }}>
                                ລາຍການ
                            </Link>
                            <Typography color="text.primary">ບັນທຶກຮັບ-ຈ່າຍ</Typography>
                        </Breadcrumbs>
                    </div>
                </div>

                {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Title text={"ເພີ່ມລາຍການຮັບ-ຈ່າຍ"} />
                </div> */}
                <div className="typeAccountTab">
                    <div className="left" style={filterType === "EXPEND" ? _active : _noActive} onClick={() => setFilterType('EXPEND')}>ລາຍຈ່າຍ</div>
                    &nbsp;
                    <div className="rigth" style={filterType === "INCOME" ? _active : _noActive} onClick={() => setFilterType('INCOME')}>ລາຍຮັບ</div>
                </div>

                <Formik
                    initialValues={{
                        dateAdd: moment().format("YYYY-MM-DD"),
                        typeAccountId: "",
                        typeIncomeAndExpendId: "",
                        qtyMoney: "",
                        currency: selectCurrency ?? "",
                       
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.dateAdd) errors.dateAdd = "ກະລຸນາຕື່ມຊື່ສິນຄ້າ"
                        if (!values.currency) errors.currency = "ກະລຸນາເລືອກສະກຸນເງິນ"
                        if (!values.typeAccountId) errors.typeAccountId = "ກະລຸນາເລືອກບັນຊີທຸລະກຳ"
                        if (!values.typeIncomeAndExpendId) errors.typeIncomeAndExpendId = "ເລືອກປະເພດທຸລະກຳ"
                        if (!values.qtyMoney) errors.qtyMoney = "ກະລຸນາປ້ອນຈຳນວນເງິນ"
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        (async () => {
                            let _qtyMoney = values.qtyMoney.toString()
                            let newMoney = parseInt(_qtyMoney.replaceAll(',', ''))
                         
                            let _data = {
                                ...values,
                                qtyMoney: newMoney ?? 0,
                                image: namePhoto,
                                type: filterType
                            }
                            delete values.qtyMoney

                            await createIncomeAndExpend(_data);
                            setSubmitting(false);
                        })();
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 10,
                                }}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={3}>
                                        <FormGroup>
                                            <FormLabel>ວັນທີ {val()}</FormLabel>
                                            <TextField
                                                error={!!errors.dateAdd}
                                                name="dateAdd"
                                                type='date'
                                                value={values?.dateAdd}
                                                onChange={handleChange}
                                                size='small'
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormGroup>
                                            <FormLabel>ເລກບິນ </FormLabel>
                                            <TextField
                                                placeholder="ສະກຸນເງິນ..."
                                                name="billNo"
                                                type='text'
                                                value={values?.billNo}
                                                onChange={handleChange}
                                                size='small'
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel>
                                                ສະກຸນເງິນ {val()}
                                            </FormLabel>
                                            <Select
                                                value={values?.currency ? selectCurrency : ""}
                                                name="currency"
                                                error={!!errors.currency}
                                                onChange={(e) => { handleChange(e?.target?.value); setSelectCurrency(e?.target.value) }}
                                                size='small'
                                            >

                                                <MenuItem value={"KIP"}>ກີບ</MenuItem>
                                                <MenuItem value={"BATH"}>ບາດ</MenuItem>
                                                <MenuItem value={"USD"}>ໂດລາ</MenuItem>
                                                <MenuItem value={"CNY"}>ຢວນ</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel>
                                                ບັນຊີທຸລະກຳ {val()}
                                            </FormLabel>
                                            <Select
                                                value={values?.typeAccountId}
                                                name="typeAccountId"
                                                error={!!errors.typeAccountId}
                                                onChange={handleChange}
                                                size='small'
                                            >
                                                <MenuItem value={""}>ເລືອກບັນຊີທຸລະກຳ</MenuItem>
                                                {accountList?.map((cur, index) =>
                                                    <MenuItem key={"cur" + index} value={cur?._id}>{cur?.accountName}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>


                                    <Grid item xs={6} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel>
                                                ປະເພດທຸລະກຳ{val()}
                                            </FormLabel>
                                            <Select
                                                value={values?.typeIncomeAndExpendId}
                                                name="typeIncomeAndExpendId"
                                                error={!!errors.typeIncomeAndExpendId}
                                                onChange={handleChange}
                                                size='small'
                                            >
                                                <MenuItem value={""}>ກະລຸນາເລືອກ</MenuItem>
                                                {typeAccountList?.map((item, index) =>
                                                    < MenuItem key={'type' + index} value={item?._id} > {item?.title}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6} md={6}>
                                        <FormGroup>
                                            <FormLabel>ຈຳນວນເງິນ</FormLabel>

                                            <NumericFormat
                                                allowLeadingZeros
                                                thousandSeparator=","
                                                decimalScale={2}
                                                allowNegative={false}
                                                value={0}
                                                type="text"
                                                placeholder="ຈຳນວນເງິນ"
                                                name="qtyMoney"
                                                customInput={TextField}
                                                onChange={handleChange}
                                                error={!!errors.qtyMoney}
                                                size='small'
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <FormGroup>
                                            <FormLabel>ລາຍລະອຽດ</FormLabel>

                                            <TextField
                                                name="note"
                                                value={values?.note}
                                                onChange={handleChange}
                                                placeholder="ລາຍລະອຽດສິນຄ້າ..."
                                                multiline
                                                InputProps={{ style: { minHeight: 100, alignItems: "start", } }}
                                                size='small'
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            {buttonUploadAndShowPhoto()}
                                        </div>
                                    </Grid>

                                </Grid>



                                <Button
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                    variant="contained"
                                    sx={{ boxShadow: 0, fontWeight: "700" }}
                                    size="large"
                                >
                                    ບັກທຶກລາຍການ
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default FormAddIncomeAndExpend