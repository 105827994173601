import React, { useRef, useState, useEffect } from "react";
import { useStore } from "../../providers/useStore";
import { useNavigate } from "react-router-dom";

//api
import { _StoresReport, _Store } from "../../apis/Stores.service"
import { PAGE_GINATION } from "../../constants"


//component
import Service from "./component/Service";


// ui
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled } from '@mui/material/styles';
import { Paper, Grid } from '@mui/material';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Divider from '@material-ui/core/Divider';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import CategoryIcon from '@material-ui/icons/Category';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  padding: 10
}));


export default function Homepage() {
  const navigate = useNavigate();

  const textAreaRef = useRef(null);
  const { storedData } = useStore();
  const [storeInfo, setStoreInfo] = useState()

  const [reports, setReports] = useState();


  //useEffect

  useEffect(() => {
    let storeLocal = window.localStorage.getItem('store');
    let storeIdLocal = JSON.parse(storeLocal)
    if (storeIdLocal === null) return;
    getStoreInfo(storeIdLocal?.storeId)

    if (storedData) {
      getData(storedData)
    }

  }, [storedData])


  //function

  const getData = async (storedData) => {
    let params = "?storeId=" + storedData?.storeId;
    const responseReport = await _StoresReport(params)
    if (!responseReport?.err) {
      setReports(responseReport?.data);
    }
  }

  const getStoreInfo = async (storedData) => {
    const param = storedData
    const responseStore = await _Store(param)
    if (responseStore?.data !== null) {
      setStoreInfo(responseStore?.data)
    }
  }


  const notifySuccess = (text) => toast.success(text, { autoClose: 1000, });
  const copyToClipboard = () => {
    textAreaRef.current.select();
    document.execCommand('copy');
    notifySuccess("ຄັດລອກລິ້ງສຳເລັດ")
  }
  return (
    <>
      <header>
        <title>Unitech-Store-{storeInfo?.name}-ໜ້າຫລັກ</title>
      </header>

      <Grid container spacing={2} style={{ padding: 20 }} >

        <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", }}>
          <p style={{ fontWeight: "700", fontSize: 16, color: "#6D6D6D" }}>ສະ​ບາຍ​ດີ, {storeInfo?.name ?? "-"}</p>
          <p onClick={() => copyToClipboard()}><font style={{ fontWeight: "400", fontSize: 12, color: "#6D6D6D", cursor: "pointer" }}>ຄັດ​ລັອກ​ລິ້ງ</font> <FileCopyIcon style={{ fontWeight: "400", fontSize: 20, color: "#6D6D6D" }} /></p>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <input type='text' style={{ width: "100%", border: 0, outline: "none" }} readOnly ref={textAreaRef} value={`https://shop.unitechlao.com/store/view-store/limit/50/skip/1/storeId/${storedData?.storeId}`} />
        </Grid>



        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Service />
        </Grid>


        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Grid container spacing={2} style={{ padding: 20 }} >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Item>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", }}>
                  <div style={{ textAlign: "left", }}>
                    <font style={{ fontSize: 32, color: "#B11A59", fontWeight: "700" }}>{reports?.totalOrder ?? "0"}</font>
                    <br></br>
                    <font style={{ fontSize: 14, color: "#6D6D6D", fontWeight: "400" }}>ປະ​ຫວັດ​ການ​ສັ່ງ​ຊື້​ສິນ​ຄ້າ</font>
                  </div>
                  <div>
                    <ListAltIcon style={{ fontSize: 64, color: "#B11A59", fontWeight: "400" }} />
                  </div>
                </div>
                <Divider style={{ marginTop: 30, marginBottom: 10 }} />
                <p onClick={() => navigate("/order-history" + PAGE_GINATION)} style={{ fontSize: 14, color: "#B11A59", fontWeight: "400", textAlign: "center", cursor: "pointer" }}>ເບິ່ງປະ​ຫວັດ​ການ​ສັ່ງ​ຊື້​ສິນ​ຄ້າ</p>
              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Item>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", }}>
                  <div style={{ textAlign: "left", }}>
                    <font style={{ fontSize: 32, color: "#1AB17B", fontWeight: "700" }}>{reports?.totalProduct ?? "0"}</font>
                    <br></br>
                    <font style={{ fontSize: 14, color: "#6D6D6D", fontWeight: "400" }}>ຈຳ​ນວນ​ສິນ​ຄ້າ​ທັງ​ໝົດ</font>
                  </div>
                  <div>
                    <LocalMallIcon style={{ fontSize: 64, color: "#1AB17B", fontWeight: "400" }} />
                  </div>
                </div>
                <Divider style={{ marginTop: 30, marginBottom: 10 }} />
                <p onClick={() => navigate('/product' + PAGE_GINATION)} style={{ fontSize: 14, color: "#1AB17B", fontWeight: "400", textAlign: "center", cursor: "pointer" }}>ເບິ່ງ​ລາຍ​ການ​ສິນ​ຄ້າ</p>
              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Item>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", }}>
                  <div style={{ textAlign: "left", }}>
                    <font style={{ fontSize: 32, color: "#1AB17B", fontWeight: "700" }}>{reports?.totalCategory ?? "0"}</font>
                    <br></br>
                    <font style={{ fontSize: 14, color: "#6D6D6D", fontWeight: "400" }}>ຈຳ​ນວນປະ​ເພດ​ສີນ​ຄ້າ</font>
                  </div>
                  <div>
                    <CategoryIcon style={{ fontSize: 64, color: "#4E74FB", fontWeight: "400" }} />
                  </div>
                </div>
                <Divider style={{ marginTop: 30, marginBottom: 10 }} />
                <p onClick={() => navigate('/product-type')} style={{ fontSize: 14, color: "#4E74FB", fontWeight: "400", textAlign: "center", cursor: "pointer" }}>ເບິ່ງ​ປະ​ເພດ​ສີນ​ຄ້າ</p>
              </Item>
            </Grid>

          </Grid>
        </Grid>









        <ToastContainer />

      </Grid>
    </>
  );
}
