import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Box, IconButton, } from "@mui/material";
import { CustomButton } from "../components"
import { useStore } from "../providers/useStore";

//constant
import { PAGE_GINATION } from "../constants"

import { _Store } from "../apis/Stores.service"


import { CgMenu } from "react-icons/cg";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CategoryIcon from '@material-ui/icons/Category';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import PaletteIcon from '@material-ui/icons/Palette';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});


// import Hidden from "../components/Hidden";

export default function PrivateLayout() {

  const navigate = useNavigate();
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const [confirmLogout, setConfirmLogout] = useState(false)
  const { storedData } = useStore();
  const [storeInfo, setStoreInfo] = useState()


  useEffect(() => {
    getStoreInfo(storedData)
  }, [storedData])

  const getStoreInfo = async (storedData) => {
    const param = storedData?.storeId
    const responseStore = await _Store(param)
    if (responseStore?.data !== null) {
      setStoreInfo(responseStore?.data)
    }
  }


  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };


  const gotoStore = () => {
    window.open(`https://shop.unitechlao.com/store/view-store/limit/50/skip/1/storeId/` + storedData?.storeId)
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ backgroundColor: "#00905E", color: "#ffffff", minHeight: "100vh" }}
    >
      <List>
        <ListItem button >
          <ListItemIcon><AccountCircleIcon style={{ color: "#ffffff", fontWeight: "900", fontSize: "40px" }} /></ListItemIcon>
          <ListItemText> <font style={{ color: "#ffffff", fontWeight: "900", fontSize: "20px" }}>{storeInfo?.name}</font></ListItemText>
        </ListItem>
        <Divider style={{ backgroundColor: "#27B43E", border: "1px solid #27B43E" }} />
        <font style={{ color: "#FFFFFF", fontSize: "20px", marginLeft: 20, fontWeight: "900" }}>ເມນູ</font>
        <ListItem button onClick={() => gotoStore()}>
          <ListItemIcon><StoreMallDirectoryIcon style={{ color: "#ffffff", fontWeight: "900", fontSize: "30px" }} /></ListItemIcon>
          <ListItemText> <font style={{ color: "#ffffff", fontWeight: "900", fontSize: "16px" }}>ໜ້າຮ້ານ</font></ListItemText>
        </ListItem>
        <ListItem button onClick={() => navigate('/home')}>
          <ListItemIcon><HomeIcon style={{ color: "#ffffff", fontWeight: "900", fontSize: "30px" }} /></ListItemIcon>
          <ListItemText> <font style={{ color: "#ffffff", fontWeight: "900", fontSize: "16px" }}>ໜ້າຫລັກ</font></ListItemText>
        </ListItem>
        <ListItem button onClick={() => navigate('/product' + PAGE_GINATION)}>
          <ListItemIcon><LocalMallIcon style={{ color: "#ffffff", fontWeight: "900", fontSize: "30px" }} /></ListItemIcon>
          <ListItemText> <font style={{ color: "#ffffff", fontWeight: "900", fontSize: "16px" }}>ເພີ່ມສິນຄ້າ</font></ListItemText>
        </ListItem>

        <ListItem button onClick={() => navigate("/order-history" + PAGE_GINATION)}>
          <ListItemIcon><ListAltIcon style={{ color: "#ffffff", fontWeight: "900", fontSize: "30px" }} /></ListItemIcon>
          <ListItemText> <font style={{ color: "#ffffff", fontWeight: "900", fontSize: "16px" }}>ປະ​ຫວັດ​ການ​ສັ່ງ​ຊື້​ສິນ​ຄ້າ</font></ListItemText>
        </ListItem>
        {/* <ListItem button onClick= {()=> navigate("/invite-friend")}>
          <ListItemIcon><SendIcon style={{ color: "#ffffff", fontWeight: "900", fontSize: "30px" }} /></ListItemIcon>
          <ListItemText> <font style={{ color: "#ffffff", fontWeight: "900", fontSize: "16px" }}>ເຊີນ​ໝູ່​ເພື່ອ​ຮັບ​ແຕ້ມ​ສະ​ສົມ</font></ListItemText>
        </ListItem> */}
        {/* <ListItem button onClick={() => navigate('/product-type')}>
          <ListItemIcon><CategoryIcon style={{ color: "#ffffff", fontWeight: "900", fontSize: "30px" }} /></ListItemIcon>
          <ListItemText> <font style={{ color: "#ffffff", fontWeight: "900", fontSize: "16px" }}>ຕັ້ງຄ່າປະເພດສິນຄ້າ</font></ListItemText>
        </ListItem>


        <ListItem button onClick={() => navigate('/color-option')}>
          <ListItemIcon><PaletteIcon style={{ color: "#ffffff", fontWeight: "900", fontSize: "30px" }} /></ListItemIcon>
          <ListItemText> <font style={{ color: "#ffffff", fontWeight: "900", fontSize: "16px" }}>ຕັ້ງຄ່າສີ</font></ListItemText>
        </ListItem> */}

        <ListItem button onClick={() => navigate('/setting-page')}>
          <ListItemIcon><SettingsIcon style={{ color: "#ffffff", fontWeight: "900", fontSize: "30px" }} /></ListItemIcon>
          <ListItemText> <font style={{ color: "#ffffff", fontWeight: "900", fontSize: "16px" }}>ຕັ້ງຄ່າ</font></ListItemText>
        </ListItem>



      </List>

    </div>
  )

  const handleLogout = async () => {
    await localStorage.clear("token")
    await localStorage.clear("store")
    await navigate('/login')
  }


  return (
    <>
      <Box sx={{
        display: "grid",
      }}
      >

        <AppBar position="static" sx={{ zIndex: 100, boxShadow: 1 }}>
          <Toolbar style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer("left", true)}
            >
              <CgMenu />
            </IconButton>


            {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              UNIMARKET
            </Typography> */}

            <div>
              {/* <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                // onClick={handleMenu}
                color="inherit"
              >
                <NotificationsIcon />
              </IconButton> */}
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                // onClick={handleMenu}
                color="inherit"
                onClick={() => navigate('/profile')}
              >
                <AccountCircleIcon />
              </IconButton>

              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setConfirmLogout(true)}
                color="inherit"
              >
                <LogoutIcon />
              </IconButton>

            </div>

          </Toolbar>
        </AppBar>


        <SwipeableDrawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {list("left")}
        </SwipeableDrawer>
      </Box>
      <Modal
        open={confirmLogout}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ overflowY: "auto" }}
      >

        <Box
          sx={{
            borderRadius: "8px",
            boxShadow: 1,
            p: 2,
            bgcolor: "background.paper",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "100%",
            maxWidth: 500,
          }}
        >
          <div style={{ fontSize: 18, fontWeight: "bold", marginBottom: 50 }}>
            ທ່ານຕ້ອງການອອກຈາກລະບົບແທ້ ຫຼື ບໍ່?
          </div>
          <div style={{ display: 'flex', justifyContent: "end" }}>
            <button
              style={{
                background: "red",
                color: "#ffffff",
                width: 140,
                height: 40,
                border: '0',
                outline: 'none',
                fontSize: "16px",
                fontWeight: "700",
                borderRadius: 5,
              }}
              onClick={() => setConfirmLogout(false)}
            >ປິດອອກ</button>
            &nbsp;
            <CustomButton onClick={() => handleLogout()} title={"ອອກຈາກລະບົບ"} />
          </div>
        </Box>

      </Modal>

    </>
  )
}
