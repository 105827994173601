/**
 * @react
 */
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

/**
 * @library
 */



/**
 * @constant
*/
import Routes from "../../constants/route"



/**
 * @apollo
 */



/**
 * @component
 */


/**
 * @function
 */



/**
 * @css
 */


import { Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

//icon
import { Breadcrumbs,Typography,Link } from "@mui/material";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BallotIcon from '@mui/icons-material/Ballot';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    padding: 10,
    cursor: "pointer"
}));

function AccountSetting() {
    const navigate = useNavigate();

    return (
        <>
            {/* <header>
                <title>Unitech-Store-ຕັ້ງຄ່າ</title>
            </header> */}

            <div style={{ display: "flex", flexDirection: "column", gap: 20, padding: 10 }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" onClick={() => navigate(Routes.ACCOUNT_DESHBOARD)} style={{ cursor: "pointer" }}>
                            ລາຍການ
                        </Link>
                        <Typography color="text.primary">ຈັດການຕັ້ງຄ່າ</Typography>
                    </Breadcrumbs>
                </div>
            </div>


            <Grid container spacing={1} style={{ padding: 5,  }} >

                <Grid item xs={4} sm={4} md={2} lg={2} onClick={() => navigate(Routes.ACCOUNT_BANK_LIST)}>
                    <Item>
                        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <AccountBalanceWalletIcon style={{ fontSize: 64, color: "#1AB17B", fontWeight: "400" }} />
                            <font style={{ fontSize: 14, color: "#6D6D6D", fontWeight: "400" }}>ບັນຊີທະນາຄານ</font>
                        </div>
                    </Item>
                </Grid>


                <Grid item xs={4} sm={4} md={2} lg={2} onClick={() => navigate(Routes.ACCOUNT_TYPE_LIST)}>
                    <Item>
                        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <BallotIcon style={{ fontSize: 64, color: "#1AB17B", fontWeight: "400" }} />
                            <font style={{ fontSize: 14, color: "#6D6D6D", fontWeight: "400" }}>ປະເພດຮັບ-ຈ່າຍ</font>
                        </div>
                    </Item>
                </Grid>
            </Grid>

            {/* <BottomNavbar /> */}

        </>
    )
}

export default AccountSetting