import React from "react";
import {
  FormGroup,
  FormLabel,
  TextField,
  Box,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useStore } from "../../providers/useStore";
import UploadImage from "../UploadImage";
import { Formik } from "formik";
import { IoMdClose } from "react-icons/io";

export default function ProductOptionForm({ value, onSubmit }) {
  const theme = useTheme();
  // state
  const { productLoading, setProductLoading } = useStore();
  const { products, setProducts } = useStore();

  return (
    <Formik
      initialValues={{
        ...value,
        name: value?.name || "",
        productId: value?.productId?._id || "none",
        detail: value?.detail || "",
        image: value?.image || [],
        price: value?.price || "",
        status: value?.status || "IN_STOCK",
        isPublic: value?.isPublic || true,
        amount: value?.amount || 0,
      }}
      validate={(values) => {
        const errors = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        (async () => {
          await onSubmit(values);
          setSubmitting(false);
        })();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <FormGroup>
              <FormLabel>ສິນຄ້າ</FormLabel>
              <Select
                name="productId"
                size="small"
                value={values?.productId}
                defaultValue="none"
                placeholder="---ເລືອກສິນຄ້າ---"
                onChange={handleChange}
              >
                <MenuItem value="none" disabled>
                  ---ເລືອກສິນຄ້າ---
                </MenuItem>
                {products?.map((e) => (
                  <MenuItem value={e?._id} key={e?._id}>
                    {e?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormGroup>
            <FormGroup>
              <FormLabel>ຊື່ Option ສິນຄ້າ</FormLabel>
              <TextField
                error={errors.name}
                placeholder="ຊື່ Option ສິນຄ້າ"
                name="name"
                value={values?.name}
                onChange={handleChange}
                size="small"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>ລາຍລະອຽດ Option ສິນຄ້າ</FormLabel>
              <TextField
                error={errors.detail}
                name="detail"
                value={values?.detail}
                onChange={handleChange}
                placeholder="ລາຍລະອຽດສິນຄ້າ..."
                multiline
                InputProps={{ style: { minHeight: 100, alignItems: "start" } }}
                size="small"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>ສະຖານະ</FormLabel>
              <Select
                name="status"
                size="small"
                value={values?.status}
                onChange={handleChange}
              >
                <MenuItem value="IN_STOCK">ມີໃນສະຕ໊ອກ</MenuItem>
                <MenuItem value="OUT_STOCK">ບໍ່ມີໃນສະຕ໊ອກ</MenuItem>
                <MenuItem value="PRE_ORDER">ພີອໍເດີ</MenuItem>
              </Select>
            </FormGroup>
            <FormGroup>
              <FormLabel>ລາຄາ</FormLabel>
              <TextField
                type="number"
                error={errors.name}
                placeholder="ລາຄາ"
                name="price"
                value={values?.price}
                onChange={handleChange}
                size="small"
              />
            </FormGroup>
            <Button
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              sx={{ boxShadow: 0 }}
              size="large"
            >
              ບັນທຶກ
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
