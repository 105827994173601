import axios from "axios";
import {BACKEND_ENPOINT} from "../constants";
// --------------------- //

const _IvitesAPI = BACKEND_ENPOINT + "/adss";
const _InviteAPI = BACKEND_ENPOINT + "/ads";
const _InviteCreateAPI = BACKEND_ENPOINT + "/invite-create-user";
const _IviteUpdateAPI = BACKEND_ENPOINT + "/ads";
const _InviteDeleteAPI = BACKEND_ENPOINT + "/ads";

export async function _Invites(params = "?") {
  try {
    const data = await axios.get(_IvitesAPI + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _Invite(_id, params) {
  try {
    const data = await axios.get(_InviteAPI + "/" + _id + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _InviteCreate(value) {
  try {
    const data = await axios.post(_InviteCreateAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _InviteUpdate(_id, value) {
  try {
    const data = await axios.put(_IviteUpdateAPI + "/" + _id, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _InviteDelete(_id) {
  try {
    const data = await axios.delete(_InviteDeleteAPI + "/" + _id);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}
