import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

// import { STORE_INFO } from "../../constant/routes"
import { URL_PHOTO_AW3 } from "../../constants"

//api
import { _Store } from "../../apis/Stores.service"
import { useStore } from "../../providers/useStore";

//components

//css
import { styled } from '@mui/material/styles';

import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import bgUser from '../../images/bgUser.jpg'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditIcon from '@mui/icons-material/Edit';

const Header = styled("div")(({ }) => ({
    padding: 0,
    margin: 0,
    width: "100%",
    position: "relative",
}));



const BodyContent = styled("div")(({ }) => ({
    top: "85%",
    width: "100%",
    backgroundImage: `url(${bgUser})`,
    position: "absolute",
    padding: 0,
    margin: 0,
    backgroundPosition: "center", /* Center the image */
    backgroundRepeat: "no-repeat", /* Do not repeat the image */
    backgroundSize: "cover",
    borderRadius: "50px 50px 0px 0px",

}))

const Image = styled("div")(({ }) => ({
    position: "absolute",
    width: "100%",
    top: "50%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    zIndex: "1",
}));

const NameStore = styled("div")(({ }) => ({
    marginTop: "20px",
    textAlign: "center",
}));

const Version = styled("div")(({ }) => ({
    marginTop: "20px",
    textAlign: "center",
    fontSize: 13,
    color: '#ACAEBE',

}));

const Manual = styled("div")(({ }) => ({
    padding: 0,
    margin: 0,
    width: "100%",
    background: "none",
    borderBottom: "1px solid #D9D9D9",
    color: "#333333",
    fontWeight: "bold",
    fontSize: "14px",
    borderRadius: "0px 0px 30px 30px",
    cursor: "pointer",

}));



function Profile_page() {
    const navigate = useNavigate()
    const { storedData } = useStore();

    const [profile, setProfile] = useState(false);
    const [storeInfo, setStoreInfo] = useState()


    useEffect(() => {
        setProfile(true)
        getStoreInfo(storedData)
    }, [storedData])

    const getStoreInfo = async (storedData) => {
        const param = storedData?.storeId
        const responseStore = await _Store(param)
        if (responseStore?.data !== null) {
            setStoreInfo(responseStore?.data)
        }
    }

    return (
        <>
            <header>
                <title>Unitech-Store-{storeInfo?.name}-ໂປຣຟາຍ</title>
            </header>

            <Slide direction="down" in={profile} >
                <Header>
                    <img src={URL_PHOTO_AW3 + "/" + storeInfo?.image}
                        style={{ width: "100%", height: 250, objectFit: "cover", }}
                        alt={storeInfo?.name ?? "-"}
                    />

                    <Image>
                        <center>
                            <img src={URL_PHOTO_AW3 + "/" + storeInfo?.image}
                                style={{ width: 160, height: 160, borderRadius: "50%", border: "6px solid #ffffff", position: "relative", objectFit: "cover" }}
                                alt={storeInfo?.name ?? "-"}
                            />
                        </center>
                        <IconButton
                            sx={{ top: "70%", left: { xs: "60%", sm: "55%", md: "52%", lg: "52%" }, position: "absolute", fontWeight: "bold", color: '#ffffff', background: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)" }}
                            aria-label={`back`}
                            onClick={() => navigate("/profile-edit" + "/" + storedData?.storeId)}
                        >
                            <EditIcon />
                        </IconButton>
                    </Image>

                    <BodyContent>
                        <Box sx={{ marginTop: { xs: "25%", md: "6%", padding: 10 } }}>
                            <p className='h3' style={{ fontWeight: "700", fontSize: 20 }}>ຂໍ້​ມູນ​ທົ່ວ​ໄປ​ຂອງ​ຮ້ານ​ຄ້າ</p>
                            <NameStore>
                                <p className='h1'>{storeInfo?.name ?? "-"}</p>
                                <p className='h2-1'>ເບີ​ໂທ: {storeInfo?.contacts?.whatsapp ?? "-"}</p>
                                <p className='h2-1'>ສະ​ຖານ​ທີ່: {storeInfo?.address ?? "-"}</p>
                            </NameStore>
                            <p className='h3' style={{ marginTop: 15, fontWeight: "900", fontSize: 20 }}>ຂໍ້​ມູນຕິ​ດ​ຕໍ່ຂອງ​ຮ້ານ​ຄ້າ</p>
                            <p className='h2-1'><font style={{ fontWeight: "400" }}>ເບີ​ໂທ:</font> <span style={{ float: 'right' }}>{storeInfo?.contacts?.phone ?? "-"}</span></p>
                            <p className='h2-1'><font style={{ fontWeight: "400" }}>ເບີວອດແອັບ:</font> <span style={{ float: 'right' }}>{storeInfo?.contacts?.whatsapp ?? "-"}</span></p>
                            <p className='h2-1'><font style={{ fontWeight: "400" }}>Facebook:</font> <span style={{ float: 'right' }}>{storeInfo?.contacts?.fb ?? "-"}</span></p>
                            <p className='h2-1'><font style={{ fontWeight: "400" }}>Page Facebook:</font><span style={{ float: 'right' }}> {storeInfo?.contacts?.page_fb ?? "-"}</span></p>
                            <p className='h2-1'><font style={{ fontWeight: "400" }}>Ticktok:</font><span style={{ float: 'right' }}> {storeInfo?.contacts?.ticktok ?? "-"}</span></p>
                            <p className='h2-1'><font style={{ fontWeight: "400" }}>Wechat:</font><span style={{ float: 'right' }}> {storeInfo?.contacts?.wechat ?? "-"}</span></p>
                            <p className='h2-1'><font style={{ fontWeight: "400" }}>Line:</font> <span style={{ float: 'right' }}>{storeInfo?.contacts?.line ?? "-"}</span></p>


                            {/* <p className='h3' style={{ marginTop: 15, fontWeight:"700",fontSize:20 }}>ກ່ຽວ​ກັບຮ້ານ​ຄ້າ</p>
                            <p style={{ color: "#6D6D6D", fontWeight: "400", fontSize: 16 }}>{storeInfo?.detail ?? "-"}</p> */}
                            <p className='h3' style={{ marginTop: 15, fontWeight: "700", fontSize: 20 }}>ຄູ່ມ​ື​ການ​ນຳ​ໃຊ້</p>

                            <Manual>
                                <List>
                                    <ListItem
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="delete">
                                                <ArrowForwardIosIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <LibraryBooksIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="ເຂ​ົ້າ​ອ່ານ​ຄູ່​ມື​ການ​ໃຊ້​ງານ"
                                        />
                                    </ListItem>
                                </List>
                            </Manual>
                            <Version>
                                <p>Version 1.1</p>
                                <p>Powered by UNIMARKET</p>
                            </Version>
                        </Box>
                    </BodyContent>
                </Header>
            </Slide>
        </>
    )
}

export default Profile_page