import axios from "axios";
import {BACKEND_ENPOINT} from "../constants";
// --------------------- //

const _ColorsAPI = BACKEND_ENPOINT + "/colors";
const _ColorAPI = BACKEND_ENPOINT + "/color";
const _ColorCreateAPI = BACKEND_ENPOINT + "/create-color";
const _ColorUpdateAPI = BACKEND_ENPOINT + "/update-color";
const _ColorDeleteAPI = BACKEND_ENPOINT + "/delete-color";

export async function _Colors(params = "?") {
  try {
    const data = await axios.get(_ColorsAPI + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _Color(_id, params = "?") {
  try {
    const data = await axios.get(_ColorAPI + "/" + _id + params);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _ColorCreate(value) {
  try {
    const data = await axios.post(_ColorCreateAPI, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _ColorUpdate(_id, value) {
  try {
    const data = await axios.put(_ColorUpdateAPI + "/" + _id, value);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}

export async function _ColorDelete(_id) {
  try {
    const data = await axios.delete(_ColorDeleteAPI + "/" + _id);
    if (data.status < 300) {
      return { err: false, data: data.data };
    }
    return { err: true, message: `err - ${data.status}` };
  } catch (err) {
    console.log("err", err);
    return { err: true, message: err };
  }
}
