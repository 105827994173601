import { useState } from "react";

export default function ColorOptionsState() {
  const [colorLoading, setColorLoading] = useState();
  const [colorList, setColorList] = useState();
  return {
    colorLoading,
    setColorLoading,
    colorList,
    setColorList,
  };
}
