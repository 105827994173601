module.exports = {
    PAGE_GINATION: '/limit/40/skip/1',
    //stock
    PRODUCT_IMPORT_STOCK: '/product-import-stock',

    //accout
    ACCOUNT_DESHBOARD: '/account-deshboard',
    ACCOUNT_SETTING:'/account-setting',
    ACCOUNT_BANK_LIST: '/account-bank-list',
    ACCOUNT_TYPE_LIST: '/account-type-list',
    ACCOUNT_ADD_INCOME_EXPEND: '/account-add-income-expend',
    ACCOUNT_EDIT_INCOME_EXPEND: '/account-edit-income-expend',
    ACCOUNT_DETAIL_INCOME_EXPEND: '/account-detail-income-expend',
  }